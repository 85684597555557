import {useRecoilState, useRecoilValueLoadable, useSetRecoilState} from "recoil";
import React, {useEffect} from "react";
import {Layer, Source} from "react-map-gl";
import {fetchedBanksUnion} from "../store/Selectors";
import {activeServiceAreaUnion, loadingServiceAreas} from "../store/Atoms";
import {serviceAreaFill} from "../map-style";

function BanksServiceArea() {
  const feature = useRecoilValueLoadable(fetchedBanksUnion);
  const [cachedFeature, setCachedFeature] = useRecoilState(activeServiceAreaUnion);
  const setLoading = useSetRecoilState(loadingServiceAreas);

  useEffect(() => {
    switch (feature.state) {
      case 'hasValue':
        setCachedFeature(feature.contents);
        setLoading(false);
        break;
      case 'loading':
        setLoading(true);
        break;
      case 'hasError':
        setLoading(false);
        throw feature.contents;
      default:
        // silence
    }
    // eslint-disable-next-line
  }, [feature]);

  if (cachedFeature && cachedFeature.type) {
    return (
      <Source
        id="service-area"
        type="geojson"
        data={cachedFeature.data.geometry}
      >
        <Layer id="service-area-fill" {...serviceAreaFill} />
      </Source>
    )
  }

  return null;
}

export default BanksServiceArea;
