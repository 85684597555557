/* eslint-disable */
import React, { Suspense } from "react";
import { Button } from "@material-ui/core";
import useStyles from "./SupplyPanel.styles";
import Skeleton from "@material-ui/lab/Skeleton";
import { useRecoilState } from "recoil";
import { reportSupplyActionFilter } from "../store/Atoms";
import cx from "classnames";
import ReportSupplyChart from "./ReportSupplyChart";
import Tooltip from "@material-ui/core/Tooltip";

export default function ReportSupply() {
  const classes = useStyles();
  const [actionFilter, setActionFilter] = useRecoilState(reportSupplyActionFilter);

  return (
    <section style={{ paddingTop: 40 }}>
      <h3 className={classes.h3}>Market Overview</h3>
      <div style={{ padding: "14px 0px 14px 0px", borderBottom: "1px dashed grey" }}>
        <Button
          onClick={() => setActionFilter(["Wetland"])}
          className={cx(classes.tiny, { active: actionFilter.includes("Wetland") })}
        >
          Wetland
        </Button>
        <Button
          onClick={() => setActionFilter(["Stream"])}
          className={cx(classes.tiny, { active: actionFilter.includes("Stream") })}
        >
          Stream
        </Button>
        <Tooltip title="Coming Soon!" placement="top">
        <span style={{cursor: "pointer"}}>
          <Button
            onClick={() => setActionFilter(["Nutrient"])}
            className={cx(classes.tiny, { active: actionFilter.includes("Nutrient") })}
            disabled
          >
            Nutrient
          </Button>
        </span>
      </Tooltip>
      <Tooltip title="Coming Soon!" placement="top">
        <span style={{cursor: "pointer"}}>
          <Button
            onClick={() => setActionFilter(["Species"])}
            className={cx(classes.tiny, { active: actionFilter.includes("Species") })}
            disabled
          >
            Species
          </Button>
        </span>
      </Tooltip>
      </div>
      <line
        x1="40"
        x2="260"
        y1="100"
        y2="100"
        stroke="#5184AF"
        stroke-width="4"
        stroke-linecap="round"
        stroke-dasharray=".001, 30"
      />
      <Suspense fallback={<Skeleton animation="wave" variant="rect" height={300} style={{ marginTop: 40 }} />}>
        <ReportSupplyChart />
      </Suspense>
    </section>
  );
}
