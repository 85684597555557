/* eslint-disable */
import React, { useState } from "react";
import { Bar, ComposedChart, Line, Tooltip, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { useRecoilValue } from "recoil";
import { fetchedReportSupply } from "../store/Selectors";
import SupplyTickers from "./SupplyTickers";

export default function ReportSupplyChart() {
  const data = useRecoilValue(fetchedReportSupply);
  const [tickers, setTickers] = useState(null);

  if (!data) return null;

  if (data.length === 0) {
    return (
      <div
        style={{
          background: "rgba(80, 243, 106, 0.02)",
          height: 300,
          width: "100%",
          marginTop: 16,
        }}
      />
    );
  }

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload) {
      const values = {
        rel: payload[0].value,
        wdr: payload[1].value,
        net: payload[2].value,
      };

      if (JSON.stringify(values) !== JSON.stringify(tickers)) {
        setTickers(values);
      }

      return (
        <div style={{}}>
          <p style={{ color: "#50F36A", fontSize: "12px" }}>{payload[0].payload.name}</p>
        </div>
      );
    } else {
      setTickers(null);
    }

    return null;
  };

  return (
    <>
      <SupplyTickers tickers={tickers} />
      <div style={{ width: "100%", height: 300 }}>
        <ResponsiveContainer>
          <ComposedChart
            width={355}
            height={156}
            data={data}
            barSize={12}
            stackOffset="sign"
            margin={{ top: 16, right: 4, left: 4, bottom: 0 }}
            style={{ background: "rgba(80, 243, 106, 0.02)", marginTop: 16 }}
          >
            <Bar dataKey="Rel" fill="#50F36A" stackId="stack" radius={[1, 1, 0, 0]} />
            <Bar dataKey="Wdr" fill="#FFFFFF" stackId="stack" radius={[1, 1, 0, 0]} />
            <Tooltip position={{ y: -10 }} content={<CustomTooltip />} />
            <Line type="monotone" dot={false} dataKey="Net" stroke="#FFFFFF" strokeWidth={2} />
            <XAxis dataKey="name" height={60} padding={{ left: 10 }} tick={{ fontSize: 12 }} />
            <YAxis orientation="right" label={{ position: "right" }} tick={{ fontSize: 12 }} />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </>
  );
}
