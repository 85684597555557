/* eslint-disable */
import { atom } from "recoil";

const selectedRegions = atom({
  key: "selectedRegions",
  default: {},
});

const activeHucs = atom({
  key: "activeHucs",
  default: [],
});

const activeBanks = atom({
  key: "activeBanks",
  default: { banks: [], seed: null },
});

const excludedBanks = atom({
  key: "excludedBanks",
  default: [],
});

const mapViewport = atom({
  key: "mapViewport",
  default: {
    latitude: 37.54129,
    longitude: -77.434769,
    zoom: 8,
    bearing: 0,
    pitch: 0,
  },
});

const reportParams = atom({
  key: "reportParams",
  default: null,
});

const bankDialogActive = atom({
  key: "bankDialogActive",
  default: null,
});

const permitDialogActive = atom({
  key: "permitDialogActive",
  default: null,
});

const permitPanelActionFilter = atom({
  key: "permitPanelActionFilter",
  default: [],
});

const permitPanelDateFilter = atom({
  key: "permitPanelDateFilter",
  default: null,
});

const reportDateFilter = atom({
  key: "reportDateFilter",
  default: null,
});

const supplyPanelActionFilter = atom({
  key: "supplyPanelActionFilter",
  default: ["Stream"],
});

const supplyPanelDateFilter = atom({
  key: "supplyPanelDateFilter",
  default: null,
});

const reportSupplyActionFilter = atom({
  key: "reportSupplyActionFilter",
  default: ["Stream"],
});

const transactionPageIndex = atom({
  key: "transactionPageIndex",
  default: 1,
});

const transCreditTypeFilters = atom({
  key: "transCreditTypeFilters",
  default: {
    Stream: true,
    Wetland: true,
    Species: true,
    Nutrient: true,
  }
});

const banksStatusTypeFilters = atom({
  key: "banksStatusTypeFilters",
  default: {
    Approved: true,
    Pending: true,
    'Sold-Out': true,
    Suspended: false,
    Terminated: false,
    Withdrawn: false,
  }
});

const transTypeFilters = atom({
  key: "transTypeFilters",
  default: {
    Rel: true,
    Wdr: true,
  }
});

const reportExcludes = atom({
  key: "reportExcludes",
  default: []
});

const permitMarkersActive = atom({
  key: "permitMarkersActive",
  default: true
});

const hucChangeTrigger = atom({
  key: "hucChangeTrigger",
  default: null
});

const hucBankParams = atom({
  key: "hucBankParams",
  default: {
    banks: [],
    hucs: [],
  }
});

const searchQuery = atom({
  key: "searchQuery",
  default: null
});

const selectedSearchLocation = atom({
  key: "selectedSearchLocation",
  default: null
});

const globalMessage = atom({
  key: "globalMessage",
  default: null
});

const isAuthorized = atom({
  key: "isAuthorized",
  default: false
});

const mapDrawType = atom({
  key: "mapDrawType",
  default: null
});

const activeDrawFeatures = atom({
  key: 'activeDrawFeatures',
  default: null,
});

const activeBankServiceArea = atom({
  key: 'activeBankServiceArea',
  default: null,
});

const activeServiceAreaUnion = atom({
  key: 'activeServiceAreaUnion',
  default: null,
});

const activesTrigger = atom({
  key: 'activesTrigger',
  default: null,
});

const showContacts = atom({
  key: 'showContacts',
  default: false,
});

const hucSelectionActive = atom({
  key: 'hucSelectionActive',
  default: false,
});

const loadingServiceAreas = atom({
  key: 'loadingServiceAreas',
  default: false,
});

export {
  selectedRegions,
  mapViewport,
  activeHucs,
  activeBanks,
  reportParams,
  bankDialogActive,
  permitDialogActive,
  permitPanelActionFilter,
  permitPanelDateFilter,
  supplyPanelActionFilter,
  supplyPanelDateFilter,
  excludedBanks,
  reportSupplyActionFilter,
  transactionPageIndex,
  transCreditTypeFilters,
  transTypeFilters,
  reportDateFilter,
  reportExcludes,
  permitMarkersActive,
  hucChangeTrigger,
  hucBankParams,
  searchQuery,
  selectedSearchLocation,
  globalMessage,
  isAuthorized,
  mapDrawType,
  activeDrawFeatures,
  activeBankServiceArea,
  activeServiceAreaUnion,
  activesTrigger,
  banksStatusTypeFilters,
  showContacts,
  hucSelectionActive,
  loadingServiceAreas,
};
