import makeStyles from "@material-ui/core/styles/makeStyles";
// import merge from "lodash.merge";

// eslint-disable-next-line no-unused-vars
export default makeStyles((theme) => ({
  xsBtn: {
    fontFamily: "Source Code Pro",
    fontSize: "12px",
    fontWeight: 700,
    textTransform: "uppercase",
    color: "#FFFFFF",
    backgroundColor: "#191919",
    borderRadius: "22px",
    height: "20px",
    marginRight: "6px",
    padding: "4px 8px 4px 8px",
    minWidth: "unset",
    "&.active": {
      backgroundColor: "#50F36A",
      color: "#191919",
    },
    "&:hover": {
      backgroundColor: "#50F36A",
      color: "#191919",
    },
    "&:focus": {
      backgroundColor: "#50F36A",
      color: "#191919",
    },
    "&:disabled": {
      opacity: "0.8",
      color: "#191919",
    },
  },
}));
