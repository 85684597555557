/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as SearchIcon} from "../assets/images/search-icon.svg";
import usePlacesAutocomplete, {getGeocode, getLatLng} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import { debounce, throttle } from 'throttle-debounce';
import {fetchData} from "../utils/Api";
import qs from "qs";
import Button from '@material-ui/core/Button';
import { useRecoilState } from "recoil";
import { selectedSearchLocation } from "../store/Atoms";
import useStyles from './LocationSearch.styles';

let baseRoute = '';

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  baseRoute = 'http://localhost:8000';
}

export default function LocationSearch() {
  const classes = useStyles();
  const [drop, setDrop] = useState(false);
  const [hucList, setHucList] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [hucLoading, setHucLoading] = useState(false);
  const [bankLoading, setBankLoading] = useState(false);
  const [selected, setSelected] = useRecoilState(selectedSearchLocation);
  const [itemClicked, setItemClicked] = useState(false);
  const [queryResults, setQueryResults] = useState(null);
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300
  });
  const history = useHistory();

  const mainAbortController = new AbortController();
  const { signal } = mainAbortController;

  async function hucSearch(value, signal) {
    try {
      setHucLoading(true);
      const values = { query: value, page_size: 5 };
      const res = await fetchData(`${baseRoute}/api/hucs/list/?${
        qs.stringify(values, { indices: false })
      }`, signal);
      if (!res) return;
      setHucList(res.results);
      collectLocalStorage(value);
      setHucLoading(false);
    } catch (e) { console.log(e) }
  }

  async function bankSearch(value, signal) {
    try {
      setBankLoading(true);
      const values = { query: value, page_size: 5 };
      const res = await fetchData(`${baseRoute}/api/banks/?${
        qs.stringify(values, { indices: false })
      }`, signal);
      if (!res) return;
      setBankList(res.results);
      collectLocalStorage(value);
      setBankLoading(false);

    } catch (e) { console.log(e) }
  }

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
    setBankList([]);
    setHucList([]);
    setDrop(false);
  });

  useEffect(() => {
    const throttled = throttle(
      300,
      (value, signal) => {
        hucSearch(value, signal);
        bankSearch(value, signal);
      },
    );
    const debounced = debounce(
      300,
      (value, signal) => {
        hucSearch(value, signal);
        bankSearch(value, signal);
      },
    );

    if (!itemClicked) {
      if (value.length < 5 && value.length !== 0) {
        throttled(value, signal);
      } else if (value.length !== 0) {
        debounced(value, signal);
      } else {
        setDrop(false);
        setBankList([]);
        setHucList([]);
      }
    } else {
      setItemClicked(false);
    }

    return () => {
      mainAbortController.abort();
      throttled.cancel();
      debounced.cancel();
    };
  }, [value]);



  useEffect(() => {
    if (hucList && hucList.length !== 0) setDrop(true);
    else if (bankList && bankList.length !== 0) setDrop(true);
    else setDrop(false);
  }, [hucList, bankList]);

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  function redirect() {
    // if user is searching from any page other than /home, it will redirect them to the correct view
    let pathname = window.location.pathname;
    if (pathname !== '/explore/') {
      history.push("/explore/");
    };
  }

  function collectLocalStorage(e) {
    localStorage.setItem('mySearch', e);
  }

  // display localStorage results
  useEffect(() => {
    let mySearch = localStorage.getItem('mySearch');
    if (mySearch) {
      // store localstorage value in stateHook
      setQueryResults(mySearch)
    };
  }, [])

  const handleSelect = ({ description }) => () => {
    redirect();
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setItemClicked(true);
    setValue(description, false);
    clearSuggestions();
    setDrop(false);
    setQueryResults(description);
    collectLocalStorage(description);
    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setSelected({
          value: description,
          lat: lat,
          long: lng,
          seed: Math.random().toString(36).substring(7),
        });
      })
      .catch((error) => {
        console.log("😱 Error: ", error);
      });
  };

  const handleFormSubmit = (e) => {
    // setQueryResults(e.target.value)
    e.preventDefault();
    e.stopPropagation();
    if (selected) {
      setSelected({
        ...selected,
        seed: Math.random().toString(36).substring(7),
      });
    }
  };

  const onHucBankClick = (label, lat, long, type) => {
    redirect();
    setItemClicked(true);
    setSelected({
      value: label,
      type: type,
      lat: lat,
      long: long,
      seed: Math.random().toString(36).substring(7),
    });
    setValue(label, false);
    clearSuggestions();
    setDrop(false);


    // setQueryResults(label);
    collectLocalStorage(label);
  }

  const renderHucList = () =>
    hucList.map((item) => {
      const { huc, name, center_lat, center_lng } = item;
      return (
        <Button className={classes.button} key={huc} onClick={() => onHucBankClick(huc, center_lat, center_lng, 'huc')}>
          <strong>{huc}</strong> <small>{name}</small>
        </Button>
      );
    });

  const renderBankList = () =>
    bankList.map((bank) => {
      const { id, name, type, longitude, latitude } = bank;
      return (
        <Button className={classes.button} key={id} onClick={() => onHucBankClick(name, latitude, longitude, 'bank')}>
          <strong>{name}</strong> <small>{type}</small>
        </Button>
      );
    });

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text }
      } = suggestion;

      return (
        <Button className={classes.button} key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </Button>
      );
    });

  return (
    <div className="filter-bar__wrapper" ref={ref}>
      <form onSubmit={handleFormSubmit}>
        <input
          type="search"
          value={
            queryResults && queryResults.length > 0
              ? queryResults
              : ''
          }
          onChange={(e) => {
            handleInput(e);
            setQueryResults(e.target.value);
          }}
          disabled={!ready}
          placeholder={"Search a location, HUC, or Bank name"}
        />
        {/* We can use the "status" to decide whether we should display the dropdown or not */}
        <Collapse in={status === "OK" || drop} className={classes.container}>
          <Paper elevation={4} className={classes.paper}>
            <div>
              <h3 className={classes.title}>HUCS</h3>
              { (status === "OK" || drop) && hucList.length > 0 ? (
                <div>{renderHucList()}</div>
              ) : (
                <div style={{fontSize: 13, marginLeft: 6}}>No Results...</div>
              )}
            </div>
            <div>
              <h3 className={classes.title} style={{marginTop: 12}}>Banks</h3>
              { (status === "OK" || drop) && bankList.length > 0 ? (
                <div>{renderBankList()}</div>
              ) : (
                <div style={{fontSize: 13, marginLeft: 6}}>No Results...</div>
              )}
            </div>
            <div>
              <h3 className={classes.title} style={{marginTop: 12}}>Locations</h3>
              { (status === "OK" || drop) && data.length > 0 ? (
                <div>{renderSuggestions()}</div>
              ) : (
                <div style={{fontSize: 13, marginLeft: 6}}>No Results...</div>
              )}
            </div>
          </Paper>
        </Collapse>
        <IconButton
          className="filter-bar__search-icon"
          aria-label="submit"
          size="small"
          onClick={handleFormSubmit}>
          <SearchIcon  />
        </IconButton>
      </form>
    </div>
  )
}

