import React from "react";
import Button from '@material-ui/core/Button';
// eslint-disable-next-line import/no-named-as-default
import {Link} from "react-router-dom";
import useStyles from "../HomePage/HomePage.styles";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import SignupForm from "../../components/SignupForm";

export default () => {
  const classes = useStyles();

  const body = document.querySelector('body');
  body.classList.add('scroll');

  const urlParams = new URLSearchParams(window.location.search);
  const betaTokenParam = urlParams.get('beta_token');

  if (!betaTokenParam || betaTokenParam !== 'DOoEzNxxVesmslGI') {
    return (
      <div className="loader-wrapper">
        <span>
          Please fill out the beta request form <Link to="/"> here</Link>, to continue.
        </span>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.headerWrapper}>
          <Logo style={{width: 115}} />
          <Link to="/sign-in">
            <Button className={classes.loginButton}>
              Sign In
            </Button>
          </Link>
        </div>
        <div className={classes.headingWrapper}>
          <h1 className={classes.headingFont}>
            Sign Up
          </h1>
        </div>
        <div className={classes.descriptionWrapper}>
          <h4 className={classes.descriptionFont}>
            Enter your account information below:
          </h4>
        </div>
        <SignupForm />
      </div>
    </div>
  );
};
