/* eslint-disable */
import makeStyles from "@material-ui/core/styles/makeStyles";
import merge from "lodash.merge";

export default makeStyles((theme) => ({
  root: merge(theme, {
    marginTop: "4px",
    marginBottom: "4px",
  }),
}));
