import React, { useEffect } from "react";
import SidePanel from "../../components/SidePanel";
import MapView from "../../components/MapView";
import PermitDialog from "../../components/PermitDialog";
import BankDialog from "../../components/BankDialog";
import FilterBar from "../../components/FilterBar";
import useStyles from "./PlatformPage.styles";
import axiosInstance from "../../utils/axiosApi";

export default () => {
  const classes = useStyles();

  const getAuthorization = async () => {
    try {
      await axiosInstance.get('/authorized');
    } catch(error) {
      console.log("Error: ", JSON.stringify(error, null, 4));
    }
    return undefined;
  }

  useEffect(() => getAuthorization(), []);

  return (
    <div className={classes.root}>
      <FilterBar />
      <SidePanel />
      <MapView />
      <BankDialog />
      <PermitDialog />
    </div>
  );
};
