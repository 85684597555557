import React from 'react';
import {numberWithCommas} from "../utils";

export default function PermitTickers({ tickers }) {
  return (
    <div className="flex" style={{color: 'white', marginTop: 16, height: 12}}>
      <div style={{fontSize: 12}} className="flex flex--column">
        <div className="flex flex--align-end">
          <div style={{height: 1, width: 8, background: 'white'}} />
          <span style={{marginLeft: 4, lineHeight: '7px'}}>Total</span>
        </div>
        { tickers && (
          <span style={{marginLeft: 11, marginTop: 1}}>{numberWithCommas(tickers.total)}</span>
        )}
      </div>
    </div>
  )
}
