import React from "react";
import ReactDOM from "react-dom";
import "./assets/styles/styles.scss";
import TagManager from 'react-gtm-module';
import App from "./App";

const tagManagerArgs = {
  gtmId: 'GTM-WS4M6MJ'
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(<App />, document.querySelector("#root"));
