import React from "react";

function AddressFormat(props) {
  const {
    name,
    title,
    address,
    address2,
    city,
    state,
    zip,
    phone,
    fax,
    cell,
  } = props;

  return (
    <address style={{ marginTop: 16, marginBottom: 16 }}>
      { title && (<>{title}<br/></>)}
      <b>{name}</b><br/>
      {address}<br/>
      { address2 && (<>{address2}<br/></>)}
      {`${city}, ${state} ${zip}`}<br/>
      { phone && (
        <>
          <a href={`tel:+1${phone}`}>Phone: {phone}</a><br/>
        </>
      )}
      { cell && (
        <>
          <a href={`tel:+1${cell}`}>Cell: {cell}</a><br/>
        </>
      )}
      { fax && (<>Fax: {fax}</>)}
    </address>
  );
}

export default AddressFormat;
