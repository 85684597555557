import React from 'react';
import {useRecoilState, useSetRecoilState} from "recoil";
import {
  EditingMode,
  DrawPolygonMode,
  DrawPointMode,
  DrawLineStringMode,
} from 'react-map-gl-draw';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Button from '@material-ui/core/Button';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';
import cx from 'classnames';
import Divider from '@material-ui/core/Divider';
import {hucSelectionActive, mapDrawType} from "../store/Atoms";
import { ReactComponent as Hand } from "../assets/images/pan_tool_black_18dp.svg";
import { ReactComponent as Trashcan } from "../assets/images/trash-can-outline.svg";
import { ReactComponent as Crosshair } from "../assets/images/crosshairs-gps.svg";
import { ReactComponent as PolygonIcon } from "../assets/images/vector-rectangle.svg";
import { ReactComponent as LineIcon } from "../assets/images/chart-timeline-variant.svg";
import { ReactComponent as HucIcon } from "../assets/images/vector-polygon.svg";
import { ReactComponent as ClearIcon } from "../assets/images/layers-clear.svg";

// eslint-disable-next-line
const useStyles = makeStyles((theme) => ({
  toggleContainer: {
    position: 'absolute',
    top: 140,
    right: 32,
    background: '#191919',
    zIndex: 9999,
  },
  toggleBtn: {
    backgroundColor: "#191919",
    height: 50,
    "&:hover": {
      backgroundColor: "#323432",
    },
    "&.active": {
      backgroundColor: "#50F36A",
      '& > span > svg': {
        fill: '#191919'
      },
      '& > span > svg > path': {
        fill: '#191919'
      }
    },
    "&:disabled": {
      opacity: "0.3",
      color: "#FFFFFF",
    },
  },
}));

function MapDrawTool({ onDelete, setMode, onClear }) {
  const classes = useStyles();
  const [type, setType] = useRecoilState(mapDrawType);
  const setHucSelect = useSetRecoilState(hucSelectionActive);

  const handleType = (event, newType) => {
    event.stopPropagation();
    event.preventDefault();

    if (newType !== null) {
      switch (newType) {
        case 'edit':
          setHucSelect(false);
          setMode(new EditingMode());
          break;
        case 'point':
          setHucSelect(false);
          setMode(new DrawPointMode());
          break;
        case 'line':
          setHucSelect(false);
          setMode(new DrawLineStringMode());
          break;
        case 'polygon':
          setHucSelect(false);
          setMode(new DrawPolygonMode());
          break;
        case 'huc':
          setMode(null);
          setTimeout(() => {
            setHucSelect(true);
          }, 500);
        break;
        default:
          // setMode(new EditingMode());
      }
      setType(newType);
    }
  };

  return (
    <ToggleButtonGroup
      value={type}
      exclusive
      orientation="vertical"
      onChange={handleType}
      aria-label="text type"
      className={classes.toggleContainer}
    >
      <Tooltip title="Edit Tool" placement="left">
        <ToggleButton className={cx(classes.toggleBtn, {active: type === 'edit'})} value="edit">
          <Hand />
        </ToggleButton>
      </Tooltip>
      <Divider orientation="horizontal" />
      <Tooltip title="Point Tool" placement="left">
        <ToggleButton className={cx(classes.toggleBtn, {active: type === 'point'})} value="point">
          <Crosshair />
        </ToggleButton>
      </Tooltip>
      <Tooltip title="Line Tool" placement="left">
        <ToggleButton className={cx(classes.toggleBtn, {active: type === 'line'})} value="line">
          <LineIcon />
        </ToggleButton>
      </Tooltip>
      <Tooltip title="Shape Tool" placement="left">
        <ToggleButton className={cx(classes.toggleBtn, {active: type === 'polygon'})} value="polygon">
          <PolygonIcon />
        </ToggleButton>
      </Tooltip>
      <Tooltip title="HUC Select Tool" placement="left">
        <ToggleButton className={cx(classes.toggleBtn, {active: type === 'huc'})} value="huc">
          <HucIcon />
        </ToggleButton>
      </Tooltip>
      <Tooltip title="Delete Layer Tool" placement="left">
        <Button className={classes.toggleBtn} onClick={onDelete}>
          <Trashcan />
        </Button>
      </Tooltip>
      <Tooltip title="Clear All Layers" placement="left">
        <Button className={classes.toggleBtn} onClick={onClear}>
          <ClearIcon />
        </Button>
      </Tooltip>
    </ToggleButtonGroup>
  )
}

export default MapDrawTool;
