import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { ReactComponent as WreathDots } from "../assets/images/wreath-dots.svg";
import { ReactComponent as GreenCheck } from "../assets/images/green-check.svg";
import {numberWithCommas} from "../utils";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#191919",
    color: theme.palette.common.white,
    fontFamily: "space Grotesk",
    borderTop: "1px dashed gray",
    borderBottom: "1px dashed gray",
    fontWeight: "700",
    fontSize: "16px",
    padding: "12px 16px 12px 16px",
    borderRight: "1px dashed gray",
  },
  body: {
    fontSize: 16,
    border: "1px dashed gray",
    padding: "12px 16px 12px 16px",
    opacity: "0.8",
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    overflowX: "none",
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    color: "white",
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  green: {
    color: '#50F36A !important',
  },
  red: {
    color: '#FF9494 !important',
  }
});

export default function CustomizedTables({ data }) {
  const classes = useStyles();

  if (!data) return null;

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="">
        <TableHead>
          <TableRow>
            <StyledTableCell align="right" />
            <StyledTableCell align="right">Stream</StyledTableCell>
            <StyledTableCell align="right">Wetland</StyledTableCell>
            <StyledTableCell align="right">Species</StyledTableCell>
            <StyledTableCell align="right">Nutrient</StyledTableCell>
            <StyledTableCell align="right" style={{ borderRight: "none" }}>
              Total
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell className={classes.label} align="left" style={{ borderLeft: "none" }}>
              <GreenCheck style={{marginRight: 6}} />
              AVL
            </StyledTableCell>
            <StyledTableCell align="right">
              {data.Stream ? numberWithCommas(parseFloat(data.Stream.Rel - data.Stream.Wdr).toFixed(2)) : null}
            </StyledTableCell>
            <StyledTableCell align="right">
              {data.Wetland ? numberWithCommas(parseFloat(data.Wetland.Rel - data.Wetland.Wdr).toFixed(2)) : null}
            </StyledTableCell>
            <StyledTableCell align="right">
              {data.Species ? numberWithCommas(parseFloat(data.Species.Rel - data.Species.Wdr).toFixed(2)) : null}
            </StyledTableCell>
            <StyledTableCell align="right">
              {data.Nutrient ? numberWithCommas(parseFloat(data.Nutrient.Rel - data.Nutrient.Wdr).toFixed(2)) : null}
            </StyledTableCell>
            <StyledTableCell align="right" style={{ borderRight: "none" }}>
              {data.Totals ? numberWithCommas(parseFloat(data.Totals.Rel - data.Totals.Wdr).toFixed(2)) : null}
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell className={classes.label} align="left" style={{ borderLeft: "none" }}>
              <WreathDots style={{marginRight: 6}} />
              WDR
            </StyledTableCell>
            <StyledTableCell className={classes.red} align="right">
              {data.Stream ? `-${numberWithCommas(parseFloat(data.Stream.Wdr).toFixed(2))}` : null}
            </StyledTableCell>
            <StyledTableCell className={classes.red} align="right">
              {data.Wetland ? `-${numberWithCommas(parseFloat(data.Wetland.Wdr).toFixed(2))}` : null}
            </StyledTableCell>
            <StyledTableCell className={classes.red} align="right">
              {data.Species ? `-${numberWithCommas(parseFloat(data.Species.Wdr).toFixed(2))}` : null}
            </StyledTableCell>
            <StyledTableCell className={classes.red} align="right">
              {data.Nutrient ? `-${numberWithCommas(parseFloat(data.Nutrient.Wdr).toFixed(2))}` : null}
            </StyledTableCell>
            <StyledTableCell className={classes.red} align="right" style={{ borderRight: "none" }}>
              {data.Totals ? `-${numberWithCommas(parseFloat(data.Totals.Wdr).toFixed(2))}` : null}
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell className={classes.label} align="left" style={{ borderLeft: "none" }}>
              <WreathDots style={{marginRight: 6}} />
              REL
            </StyledTableCell>
            <StyledTableCell className={classes.green} align="right">
              {data.Stream ? `+${numberWithCommas(parseFloat(data.Stream.Rel).toFixed(2))}` : null}
            </StyledTableCell>
            <StyledTableCell className={classes.green} align="right">
              {data.Wetland ? `+${numberWithCommas(parseFloat(data.Wetland.Rel).toFixed(2))}` : null}
            </StyledTableCell>
            <StyledTableCell className={classes.green} align="right">
              {data.Species ? `+${numberWithCommas(parseFloat(data.Species.Rel).toFixed(2))}` : null}
            </StyledTableCell>
            <StyledTableCell className={classes.green} align="right">
              {data.Nutrient ? `+${numberWithCommas(parseFloat(data.Nutrient.Rel).toFixed(2))}` : null}
            </StyledTableCell>
            <StyledTableCell className={classes.green} align="right" style={{ borderRight: "none" }}>
              {data.Totals ? `+${numberWithCommas(parseFloat(data.Totals.Rel).toFixed(2))}` : null}
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
