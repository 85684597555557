/* eslint-disable */
import React, {Suspense} from "react";
import {useRecoilState} from "recoil";
import {reportDateFilter} from "../store/Atoms";
import DateFilterBar from "./DateFilterBar";
import useStyles from './ReportSideNav.style';
import ReportBanksFilter from "./ReportsBankFilter";

export default function SideNavFilter() {
  const classes = useStyles();
  const [dateFilter, setDateFilter] = useRecoilState(reportDateFilter);

  return (
    <div className={classes.root}>
      <div className={classes.sideNav}>
        <h3 className={classes.h3}>Date Range</h3>
        <DateFilterBar filter={dateFilter} setFilter={setDateFilter} variation="market-overview" />
        <h3 className={classes.h3} style={{ borderBottom: "none", marginTop: 40 }}>
          Include Banks
        </h3>
        <Suspense fallback={<div>Loading...</div>}>
          <ReportBanksFilter />
        </Suspense>
      </div>
    </div>
  );
}
