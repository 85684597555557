/* eslint-disable */
import React from "react";
import { Marker } from "react-map-gl";
import { ReactComponent as Point } from "../assets/images/bank-pin.svg";
import {activeBankServiceArea, bankDialogActive} from "../store/Atoms";
import { useSetRecoilState } from "recoil";
import cx from "classnames";

// Important for perf: the markers never change, avoid rerender when the map viewport changes
function BankPins(props) {
  const { data, setPopupInfo } = props;
  const setDialogOpen = useSetRecoilState(bankDialogActive);
  const setActive = useSetRecoilState(activeBankServiceArea);

  return data.map((bank, index) => {
    if (bank.latitude && bank.longitude) {
      const net = bank.credits.Totals.Rel - bank.credits.Totals.Wdr;
      return (
        <Marker key={`marker-${index}`} longitude={parseFloat(bank.longitude)} latitude={parseFloat(bank.latitude)}>
          <Point
            className={cx({ grey: net <= 0 })}
            style={{ cursor: "pointer" }}
            onMouseEnter={() => {
              setActive(bank);
              setPopupInfo({
                ...bank,
                type: "Bank",
              })
            }}
            onMouseLeave={() => {
              setActive(null);
              setPopupInfo(null);
            }}
            onClick={() => {
              setPopupInfo(null);
              setDialogOpen(bank);
            }}
          />
        </Marker>
      );
    }

    return null;
  });
}

export default React.memo(BankPins);
