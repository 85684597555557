import {makeStyles} from "@material-ui/core/styles";

export default makeStyles(() => ({
  root: {
    // backgroundColor: "#191919",
    borderRadius: "4px",
    display: "flex",
    flexWrap: "wrap",
    // height: "100vh",
    marginTop: 56
  },
  sideNav: {
    backgroundColor: "#232423",
    width: "337px",
    height: "auto",
    padding: "32px",
    marginLeft: "49px",
  },
  h3: {
    fontFamily: "Space Grotesk",
    fontSize: "24px",
    fontWeight: "700",
    padding: "0px 0px 16px 0px",
    color: "white",
    borderBottom: "1px dashed gray",
  },
  xsBtn: {
    fontFamily: "Source Code Pro",
    fontSize: "12px",
    fontWeight: 700,
    textTransform: "uppercase",
    color: "#FFFFFF",
    backgroundColor: "#191919",
    borderRadius: "22px",
    height: "20px",
    marginRight: "16px",
    padding: "4px 8px 4px 8px",
    minWidth: "unset",
    "&.active": {
      backgroundColor: "#50F36A",
      color: "#191919",
    },
    "&:hover": {
      backgroundColor: "#50F36A",
      color: "#191919",
    },
    "&:active": {
      backgroundColor: "#50F36A",
      color: "#191919",
    },
    "&:focus": {
      backgroundColor: "#50F36A",
      color: "#191919",
    },
    "&:disabled": {
      opacity: "0.8",
      color: "#191919",
    },
  },

  accordion: {
    width: "273px",
    border: 'none',
    boxShadow: 'none',
    borderTop: "1px dashed gray !important",
    // padding: "16px 0px 15px 0px",
    height: "auto",
    backgroundColor: "#232423",
  },
  summary: {
    height: "67px",
    backgroundColor: "#232423",
  },
  inputLabel: {
    zIndex: "15",
    marginTop: "-17px",
    marginLeft: "12px",
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontWeight: 500,
    textTransform: "none",
    color: "#FFFFFF",
    underline: "none",
    opacity: "0.8",
    paddingTop: "17px",
  },
  accordionDetails: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#232423",
  },
  heading: {
    // backgroundColor: "#232423",
  },
  bulkCheckbox: {
    position: 'absolute',
    marginBottom: 1,
  },
  bulkSelectControl: {
    height: 40,
    justifyContent: 'center',
    '& > .MuiInputBase-root > fieldset': {
      border: 'none',
    },
    '& > .MuiInputBase-root > .MuiSelect-outlined': {
      padding: '14px 23px',
      paddingRight: 32,
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      borderRadius: 4,
    },
  },
  bulkSelectWrapper: {
    display: 'flex',
  },
  deleteSelected: {
    marginRight: 7,
  },
}));
