import React, { useState } from 'react';
import Button from "@material-ui/core/Button";
import useStyles from './RecoveryPage.styles';
import {ReactComponent as Logo} from "../../assets/images/logo.svg";
import RecoverUsernameForm from '../../components/RecoverUsernameForm';
import RecoverPasswordForm from '../../components/RecoverPasswordForm';


const RecoveryPage = () => {
  const classes = useStyles();
  const [recoverUsername, setRecoverUsername] = useState(true);
  const [recoverPassword, setRecoverPassword] = useState(!recoverUsername);

  function showRecoverUsernameForm(value) {
    setRecoverUsername(value);
    setRecoverPassword(!value);
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.headerWrapper}>
          <Logo style={{width: 115}} />
        </div>
        <div className={classes.headingWrapper}>
          <h1 className={classes.headingFont}>
            {recoverUsername ? 'Forgot Username' : 'Forgot Password'}
          </h1>
        </div>
        <div className={classes.descriptionWrapper}>
          {recoverUsername && (
            <RecoverUsernameForm />
          )}
          {recoverPassword && (
            <RecoverPasswordForm />
          )}
        </div>
        <Button className={classes.submitButton} onClick={() => showRecoverUsernameForm(true)}>Forgot Username?</Button>
        <Button className={classes.submitButton} onClick={() => showRecoverUsernameForm(false)}>Forgot Password?</Button>
      </div>
    </div>
  )
};

export default RecoveryPage;
