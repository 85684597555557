import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {useRecoilValue} from "recoil";
import {globalMessage} from '../store/Atoms';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function GlobalMessage() {
  const [severity, setSeverity] = useState('info');
  const [open, setOpen] = useState(false);
  const message = useRecoilValue(globalMessage);

  useEffect(() => {
    if (message) {
      setSeverity(message.severity);
      setOpen(true);
    }
  }, [message]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity}>
        {message?.text}
      </Alert>
    </Snackbar>
  )
}

export default GlobalMessage;
