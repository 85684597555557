import React from "react";
import { Link } from 'react-router-dom';
// eslint-disable-next-line import/no-named-as-default
import useStyles from "../HomePage/HomePage.styles";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import SigninForm from "../../components/SigninForm";

export default () => {
  const classes = useStyles();

  const body = document.querySelector('body');
  body.classList.add('scroll');

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.headerWrapper}>
          <Logo style={{width: 115}} />
        </div>
        <div className={classes.headingWrapper}>
          <h1 className={classes.headingFont}>
            Sign In
          </h1>
        </div>
        <div className={classes.descriptionWrapper}>
          <h4 className={classes.descriptionFont}>
            Enter you account information below:
          </h4>
        </div>
        <SigninForm />
        <Link to="/recovery" className={classes.helperFont}>Forgot username or password?</Link>
      </div>
    </div>
  );
};
