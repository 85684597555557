import React, { useState, useRef, Suspense, useCallback } from "react";
import MapGL, {Source, Layer, FlyToInterpolator, GeolocateControl} from "react-map-gl";
import { Editor } from 'react-map-gl-draw';
import mapboxgl from "mapbox-gl/dist/mapbox-gl";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {
  mapViewport,
  permitMarkersActive,
  mapDrawType,
  activeDrawFeatures,
  permitDialogActive,
  hucSelectionActive, loadingServiceAreas,
} from "../store/Atoms";
import {
  huc08Lines,
  huc08Fill,
  huc08labels ,
  getFeatureStyle,
  getEditHandleStyle
} from "../map-style";
import PermitMarkers from "./PermitMarkers";
import BankMarkers from "./BankMarkers";
import LocationDropper from "./LocationDropper";
import MapDrawTool from "./MapDrawTool";
import PermitPopup from "./PermitPopup";
import BankPopup from "./BankPopup";
import ActiveBankServiceArea from "./ActiveBankServiceArea";
import BanksServiceArea from "./BankServiceArea";
import MapLegend from "./MapLegend";
import MapLoader from "./MapLoader";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

function MapView() {
  const mapRef = useRef();
  const [mode, setMode] = useState(null)
  const editorRef = useRef(null);
  const [selectedFeatureIndex, setSelectedFeatureIndex] = useState(null);
  const [viewport, setViewport] = useRecoilState(mapViewport);
  const permitsActive = useRecoilValue(permitMarkersActive);
  const [hoverId, setHoverId] = useState(null);
  const [popupInfo, setPopupInfo] = useState(null);
  const [bankPopupInfo, setBankPopupInfo] = useState(null);
  const setType = useSetRecoilState(mapDrawType);
  const [activeFeatures, setActiveFeatures] = useRecoilState(activeDrawFeatures);
  const setDialogOpen = useSetRecoilState(permitDialogActive);
  const [hucSelect, setHucSelect] = useRecoilState(hucSelectionActive);
  const geolocateStyle = { top: 0, left: 0, margin: 10 };
  const positionOptions = {enableHighAccuracy: true};
  const setLoading = useSetRecoilState(loadingServiceAreas);

  const Initializer = () => {
    if (activeFeatures && activeFeatures.features.length) {
      const feats = activeFeatures.features.map((item) => (
        {
          type: "Feature",
          properties:{},
          geometry: JSON.parse(item)
        }
      ))
      editorRef.current.addFeatures(feats);
    }
  };

  const onHover = (e) => {
    const prevId = hoverId;
    const region = e.features && e.features[0];
    const map = mapRef.current.getMap();

    if (typeof map.getLayer('unclustered-point') !== 'undefined') {
      const clusterPointFeatures = map.queryRenderedFeatures(e.point, {
        layers: ['unclustered-point']
      });

      if (clusterPointFeatures.length) {
        const { properties } = clusterPointFeatures[0];
        setPopupInfo({
          ...properties,
          type: 'ORM Permit',
          name: properties.project_name,
          latitude: parseFloat(properties.project_latitude),
          longitude: parseFloat(properties.project_longitude)
        })
      } else {
        setPopupInfo(null);
      }
    }

    if (hucSelect && region?.id) {
      if (prevId) {
        map.setFeatureState({ source: "huc08-source", sourceLayer: "huc08", id: prevId }, { hover: false });
      }
      map.setFeatureState({ source: "huc08-source", sourceLayer: "huc08", id: region.id }, { hover: true });
      setHoverId(region.id);
    } else {
      if (prevId) {
        map.setFeatureState({ source: "huc08-source", sourceLayer: "huc08", id: prevId }, { hover: false });
      }
      setHoverId(null);
    }
  };

  const onClick = (e) => {
    const map = mapRef.current;
    const mapController = map.getMap();
    const features = map.queryRenderedFeatures(e.point);
    setPopupInfo(null);
    setBankPopupInfo(null);

    if (typeof mapController.getLayer('unclustered-point') !== 'undefined') {
      const clusterPointFeatures = map.queryRenderedFeatures(e.point, {
        layers: ['unclustered-point']
      });

      if (clusterPointFeatures.length) {
        setDialogOpen(clusterPointFeatures[0].properties);
      }
    }

    if (typeof mapController.getLayer('clusters') !== 'undefined') {
      const clusterFeatures = map.queryRenderedFeatures(e.point, {
        layers: ['clusters']
      });

      if (clusterFeatures.length) {
        const clusterId = clusterFeatures[0].properties.cluster_id;
        mapController.getSource('permits').getClusterExpansionZoom(
          clusterId,
          (err, zoom) => {
            if (err) return;
            setViewport({
              ...viewport,
              longitude: clusterFeatures[0].geometry.coordinates[0],
              latitude: clusterFeatures[0].geometry.coordinates[1],
              zoom,
              transitionInterpolator: new FlyToInterpolator({speed: 0.8}),
              transitionDuration: 500
            });
          }
        );
      }
    }

    if (hucSelect && typeof mapController.getLayer('huc08-fill') !== 'undefined') {
      const hucFeatures = map.queryRenderedFeatures(e.point, {
        layers: ['huc08-fill']
      });

      if (hucFeatures.length) {
        const drawFeatures = editorRef.current.getFeatures();
        const exists = drawFeatures.map((feature) => feature.properties).find(f => f.huc8 === hucFeatures[0]);

        if (!exists) {
          editorRef.current.addFeatures(features[0]);
          const data = [...editorRef.current.getFeatures(), features[0]];
          const geomSet = data.map((item) => JSON.stringify(item.geometry));
          setActiveFeatures({ features: geomSet });
          setHucSelect(false);
          setType(null);
          setLoading(true);
        }
      }
    }
  };

  const onSelect = useCallback(options => {
    setSelectedFeatureIndex(options && options.selectedFeatureIndex);
  }, []);

  const onDelete = useCallback(() => {
    if (selectedFeatureIndex !== null && selectedFeatureIndex >= 0) {
      editorRef.current.deleteFeatures(selectedFeatureIndex);
      const list = editorRef.current.getFeatures();
      let newList = [];
      if (list.length > 1) {
        list.splice(selectedFeatureIndex, 1);
        newList = list.map((item) => JSON.stringify(item.geometry));
      }
      setActiveFeatures({ features: newList });
      setType(null);
      setMode(null);
      if (newList.length) {
        setLoading(true);
      }
    }
    // eslint-disable-next-line
  }, [selectedFeatureIndex]);

  const onUpdate = useCallback((props) => {
    const { editType, data } = props;
    if (editType === 'addFeature') {
      const geomSet = data.map((item) => JSON.stringify(item.geometry));
      setActiveFeatures({ features: geomSet });
      setType(null);
      setMode(null);
      setLoading(true);

    }
    // eslint-disable-next-line
  }, []);

  const onClear = useCallback(() => {
    const drawFeatures = editorRef.current.getFeatures().map((_, index) => index);
    editorRef.current.deleteFeatures(drawFeatures);
    setActiveFeatures([]);
    // eslint-disable-next-line
  }, []);

  return (
    <article className="map-view">
      <MapGL
        ref={mapRef}
        {...viewport}
        width="100%"
        height="100%"
        mapStyle="mapbox://styles/dev-at-mobelux-com/ckk76azvm03dm17o2yxc5xnzs"
        mapboxApiAccessToken="pk.eyJ1IjoiZGV2LWF0LW1vYmVsdXgtY29tIiwiYSI6ImNranllZHZoMDAzMmgydmxxNmhmY2tib3YifQ.HxmGUqI-BupGHD8LV1zkIQ"
        onViewportChange={(nextViewport) => {
          setPopupInfo(null);
          setViewport(nextViewport);
        }}
        interactiveLayerIds={["huc08-fill"]}
        onHover={onHover}
        onClick={onClick}
        onLoad={Initializer}
      >
        <Editor
          ref={editorRef}
          style={{width: '100%', height: '100%'}}
          clickRadius={12}
          mode={mode}
          onSelect={onSelect}
          onUpdate={onUpdate}
          editHandleShape="circle"
          featureStyle={getFeatureStyle}
          editHandleStyle={getEditHandleStyle}
        />
        <MapDrawTool onDelete={onDelete} setMode={setMode} onClear={onClear} />
        <Suspense fallback={null}>
          <LocationDropper mapRef={mapRef} editorRef={editorRef} />
        </Suspense>
        <BankMarkers setPopupInfo={setBankPopupInfo} />
        { permitsActive && (
          <PermitMarkers setPopupInfo={setPopupInfo} mapRef={mapRef} />
        )}
        <BankPopup popupInfo={bankPopupInfo} setPopupInfo={setBankPopupInfo} />
        <PermitPopup popupInfo={popupInfo} setPopupInfo={setPopupInfo} />
        <Suspense fallback={null}>
          <BanksServiceArea />
        </Suspense>
        <ActiveBankServiceArea mapRef={mapRef} />
        <Source id="huc08-source" type="vector" url="mapbox://dev-at-mobelux-com.huc08">
          <Layer {...huc08Lines} />
          <Layer {...huc08labels} />
          <Layer id="huc08-fill" {...huc08Fill} />
        </Source>
        <GeolocateControl
          onViewportChange={(nextViewport) => {
            setPopupInfo(null);
            setViewport({...nextViewport, zoom: 8 });
          }}
          showUserLocation={false}
          style={geolocateStyle}
          positionOptions={positionOptions}
          trackUserLocation
          fitBoundsOptions={{ maxZoom: 0 }}
          auto
        />
        <MapLegend />
        <MapLoader />
      </MapGL>
    </article>
  );
}

export default MapView;
