import React  from 'react';
import { useParams } from 'react-router-dom';
import useStyles from '../HomePage/HomePage.styles';
import {ReactComponent as Logo} from "../../assets/images/logo.svg";
import ResetPasswordForm from '../../components/ResetPasswordForm';


const RecoveryPage = () => {
  const params = useParams();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.headerWrapper}>
          <Logo style={{width: 115}} />
        </div>
        <div className={classes.headingWrapper}>
          <h1 className={classes.headingFont}>Reset Password</h1>
        </div>
        <div className={classes.descriptionWrapper}>
          <ResetPasswordForm resetToken={params.resetToken}/>
        </div>
      </div>
    </div>
  )
};

export default RecoveryPage;
