import React from 'react';
import Collapse from "@material-ui/core/Collapse";
import {useRecoilValue} from "recoil";
import useStyles from "./BankDialog.styles";
import { showContacts } from "../store/Atoms";
import {fetchedBankDetails} from "../store/Selectors";
import AddressFormat from "./AddressFormat";

function BankContacts() {
  const classes = useStyles();
  const details = useRecoilValue(fetchedBankDetails);
  const checked = useRecoilValue(showContacts);
  const {
    managers,
    pocs,
    sponsors,
  } = details;

  if (!details) return null;

  return (
   <section>
      <Collapse in={checked}>
        <h4 className={classes.h4}>
          Managers
        </h4>
        <div className={classes.divider}/>
        <div className="grid no-gap">
          {managers.map((manager) => <AddressFormat {...manager} />)}
        </div>
        <h4 className={classes.h4}>
          POCs
        </h4>
        <div className={classes.divider}/>
        <div className="grid no-gap">
          {pocs.map((poc) => <AddressFormat {...poc} />)}
        </div>
        <h4 className={classes.h4}>
          Sponsors
        </h4>
        <div className={classes.divider}/>
        <div className="grid no-gap">
          {sponsors.map((sponsor) => <AddressFormat {...sponsor} />)}
        </div>
      </Collapse>
    </section>
  );
}

export default BankContacts;
