import makeStyles from "@material-ui/core/styles/makeStyles";
// import merge from "lodash.merge";

// eslint-disable-next-line no-unused-vars
export default makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
  },

  filterButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontWeight: 500,
    textTransform: "none",
    borderRadius: "44px",
    height: "32px",
    marginRight: "8px",
    padding: "0px 16px 0px 16px",

  },

  greyBtn: {
    color: "#FFFFFF",
    backgroundColor: "#232423",
    "&:hover": {
      backgroundColor: "#323432",
    },
    "&.active": {
      backgroundColor: "#282A28",
    },
    "&:disabled": {
      opacity: "0.3",
      color: "#FFFFFF",
    },
  },

  greenBtn: {
    color: "#191919",
    backgroundColor: "#50F36A",
    "&:hover": {
      backgroundColor: "#AEF9BA",
    },
    "&:focus": {
      backgroundColor: "#AEF9BA",
    },
    "&.active": {
      backgroundColor: "#8DF79E",
    },
    "&:disabled": {
      opacity: "0.3",
    },
  },

  whiteBtn: {
    color: "#191919",
    backgroundColor: "#FFFFFF",
    "&.active": {
      opacity: 0.5,
      backgroundColor: "#D0D0D0",
      "&:hover": {
        opacity: 1,
        backgroundColor: "#D0D0D0",
    },
    },
    "&:hover": {
      backgroundColor: "#D0D0D0",
    },
    "&:focus": {
      backgroundColor: "#FFFFFF",
    },
    "&:active": {
      opacity: 0.5,
      backgroundColor: "#D0D0D0",
    },
    "&:disabled": {
      opacity: "0.3",
    },
  }
}));
