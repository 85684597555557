import React from 'react';
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core/styles";
import { ReactComponent as BankPoint } from "../assets/images/bank-pin.svg";

// eslint-disable-next-line
const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    top: 140,
    right: 110,
    background: '#191919',
    zIndex: 1,
  },
  list: {
    listStyle: 'none',
    padding: 12,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    '&:not(:first-child)': {
      marginTop: 2,
    }
  }
}));

function MapLegend() {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <ul className={classes.list}>
        <li className={classes.listItem}>
          <BankPoint style={{marginRight: 4}} />
          Active Bank
        </li>
        <li className={classes.listItem}>
          <BankPoint style={{marginRight: 4}} className="grey" />
          Zero Credits Bank
        </li>
        <li className={classes.listItem}>
          <BankPoint style={{marginRight: 4}} className="white" />
          Permit
        </li>
      </ul>
    </Paper>
  );
}

export default MapLegend;
