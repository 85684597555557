import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

export default createMuiTheme({
  palette: {
    primary: {
      main: "#FFFFFF",
    },
    secondary: {
      main: "#6200EE",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#191919",
    },
    black1: "#232423",
    black2: "#323432",
    black3: "#282A28",
    black4: "#191919",
    green1: "#50F36A",
    green2: "#AEF9BA",
    green3: "#8DF79E",
  },
  typography: {
    fontFamily: ["Source Sans Pro", "Space Grotesk", "Source Code Pro", "sans-serif"].join(","),
    h1: {
      fontFamily: "Space Grotesk",
      fontWeight: 700,
      fontSize: "32px",
      lineHeight: "40px",
    },

    h2: {},

    h3: {
      fontFamily: "Space Grotesk",
      fontSize: "24px",
      fontWeight: "700",
    },

    tiny: {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "16px",
      opacity: "0.8",
    },

    body1: {
      fontFamily: "Source Sans Pro",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "16px",
    },
    button: {
      fontFamily: "Source Sans Pro",
      fontSize: "16px",
      fontWeight: 500,
      textTransform: "none",
      color: "#FFFFFF",
      backgroundColor: "#232423",
      borderRadius: "44px",
      "&:hover": {
        backgroundColor: "#323432",
      },
      "&:active": {
        backgroundColor: "#282A28",
      },
      "&:focus": {
        backgroundColor: "#282A28",
      },
    },
  },
});
