/* eslint-disable */
import React, { useEffect } from "react";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import {
  activeBanks,
  activeHucs,
  activesTrigger,
  bankDialogActive,
  hucBankParams,
  supplyPanelActionFilter
} from "../store/Atoms";
import { fetchedBanks } from "../store/Selectors";
import useStyles from "./BanksPanel.styles";
import greenCheck from "../assets/images/green-check.svg";
import wreathDots from "../assets/images/wreath-dots.svg";
import IOSSwitch from "./GreenSwitch2";
import {numberWithCommas, randomString} from "../utils";
import Tooltip from "@material-ui/core/Tooltip";
import BulkSelect from "./BulkSelect";
import SupplyCreditFilter from "./SupplyCreditFilter";

export default function BanksPanel() {
  const classes = useStyles();
  const banks = useRecoilValue(fetchedBanks);
  const hucs = useRecoilValue(activeHucs);
  const setActive = useSetRecoilState(activeBanks);
  const { results } = banks;
  const setDialogOpen = useSetRecoilState(bankDialogActive);
  const setParams = useSetRecoilState(hucBankParams);
  const actionFilter = useRecoilValue(supplyPanelActionFilter);

  useEffect(() => {
    let newActives = [];
    let newHucs = [];

    if (results && results.length > 0) {
      newHucs = [...hucs];
      newActives = results.map((bank) => bank.id);
    }

    setParams({ banks: newActives, hucs: newHucs });
    setActive({ banks: newActives, seed: randomString() });
  }, [banks]);

  let sorted = [];
  if (results) {
    sorted = [...results];
    sorted.sort((a, b) => parseFloat(b.credits[actionFilter[0]].Net) - parseFloat(a.credits[actionFilter[0]].Net));
  }

  return (
    <section className={classes.root}>
      <h3 className={classes.h3}>Banks</h3>
      <SupplyCreditFilter />
      <ul className={classes.ul} style={{ paddingTop: 0 }}>
        {sorted.length > 0 && (
          <>
            {sorted.map((bank) => (
              <li key={bank.name} className={classes.li}>
                <div className={classes.bankTop}>
                  <span className={classes.bankTitle} onClick={() => setDialogOpen(bank)}>
                    {bank.name}
                  </span>
                  <IOSSwitch item={bank} />
                </div>
                <div className={classes.bankData}>
                  <Tooltip title="Available">
                    <p className="flex" style={{ cursor: 'default' }}>
                      <img alt='avail' className={classes.img} src={greenCheck} />
                      {numberWithCommas(bank.credits[actionFilter[0]].Net)}
                    </p>
                  </Tooltip>
                  <p className="flex ">
                    <img src={wreathDots}  alt='rel/wdr' />
                    <Tooltip title="Released">
                      <span style={{ cursor: 'default' }}>
                        +{bank.credits && numberWithCommas(bank.credits[actionFilter[0]].Rel)}
                      </span>

                    </Tooltip>
                     /
                    <Tooltip title="Withdrawn">
                      <span style={{ cursor: 'default' }}>
                        -{numberWithCommas(bank.credits[actionFilter[0]].Wdr)}
                      </span>
                    </Tooltip>
                  </p>
                </div>
              </li>
            ))}
          </>
        )}
      </ul>
    </section>
  );
}
