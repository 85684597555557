import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Button from "@material-ui/core/Button";
import {useSetRecoilState} from "recoil";
import { Redirect } from "react-router-dom";
import InputField from "./InputField";
import useStyles from './BetaTesterForm.style';
import {globalMessage} from "../store/Atoms";
import axiosInstance from "../utils/axiosApi";

const SignupSchema = Yup.object().shape({
  username: Yup.string().required('Required'),
  email: Yup.string().required('Required'),
  password: Yup.string()
    .required('Please Enter your password')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
  password_confirmation: Yup.string().when("password", {
    is: val => (!!(val && val.length > 0)),
    then: Yup.string().oneOf(
      [Yup.ref("password")],
      "Both passwords need to be the same"
  )
 })
});

function SignupForm() {
  const classes = useStyles();
  const [redirect, setRedirect] = useState(false);
  const setMessage = useSetRecoilState(globalMessage);

  const initial = {
    username: '',
    email: '',
    password: '',
    password_confirmation: '',
  }

  const handleSubmit = async (values, { resetForm }) => {
    const newValues = { ...values };
    delete newValues.password_confirmation;
    try {
      const response = await axiosInstance.post('/user/create/', values);
      if (response.status === 201) {
        setMessage({
          severity: 'success',
          text: 'You have successfully submitted your request!'
        });
      }
      setRedirect(true);
      resetForm();
    } catch (error) {
      console.log(error.stack);
      setMessage({
        severity: 'error',
        text: 'Error: something went wrong with submitting request.'
      });
    }
    return undefined;
  };

  if (redirect) {
    return <Redirect to='sign-in' />;
  }

  return (
    <Formik
       initialValues={initial}
       validationSchema={SignupSchema}
       onSubmit={handleSubmit}
     >
      {/* eslint-disable-next-line no-unused-vars */}
       {({errors, touched}) => (
         <Form>
           <section className={classes.form}>
             <InputField
               name='username'
               label='Username'
               className={classes.inputField}
               error={errors.first_name && touched.first_name}
               helperText={errors.first_name}
             />
             <InputField
               name='email'
               label='Email'
               className={classes.inputField}
               error={errors.email && touched.email}
               helperText={errors.email}
             />
             <InputField
               name='password'
               label='Password'
               className={classes.inputField}
               error={errors.password && touched.password}
               helperText={errors.password}
               type='password'
             />
             <InputField
               name='password_confirmation'
               label='Confirm Password'
               className={classes.inputField}
               error={errors.password_confirmation && touched.password_confirmation}
               helperText={errors.password_confirmation}
               type='password'
             />
           </section>
           <div className={classes.submitWrapper}>
             <Button
               className={classes.submitBtn}
               type='submit'
             >
               Sign Up
             </Button>
           </div>
         </Form>
       )}
     </Formik>
  )
}

export default SignupForm;
