/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { fetchedBanks } from "../store/Selectors";
import BankPins from "./BankPins";
import { activeBanks, excludedBanks } from "../store/Atoms";

function BankMarkers({ setPopupInfo }) {
  const banks = useRecoilValueLoadable(fetchedBanks);
  const actives = useRecoilValue(activeBanks);
  const excludes = useRecoilValue(excludedBanks);
  const [cachedBanks, setCachedBanks] = useState([]);

  useEffect(() => {
     switch (banks.state) {
       case 'hasValue':
        const { results } = banks.contents;
        if (results && results.length > 0) {
          setCachedBanks(results.filter(item => actives.banks.includes(item.id)));
        } else {
          setCachedBanks([]);
        }
        break;
      case 'loading':
        break;
      case 'hasError':
        throw banks.contents;
    }
  }, [actives]);

  useEffect(() => {
    const { results } = banks.contents;
    if (results && results.length > 0) {
      setCachedBanks(results.filter(item => !excludes.includes(item.id)));
    }
  }, [excludes]);

  if (cachedBanks && cachedBanks.length > 0) {
    return (
      <BankPins data={cachedBanks} setPopupInfo={setPopupInfo} />
    )
  }

  return null;
}

export default BankMarkers;
