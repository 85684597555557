import React, { useEffect, useState } from "react";
import {RecoilRoot, useRecoilValue, useSetRecoilState} from "recoil";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import PlatformPage from "./views/PlatformPage/PlatformPage";
import HomePage from "./views/HomePage/HomePage";
import ReportsPage from "./views/ReportsPage/ReportsPage";
import AppHeader from "./components/AppHeader";
import GlobalMessage from "./components/GlobalMessage";
import axiosInstance from "./utils/axiosApi";
import SignupPage from "./views/SignupPage/SignupPage";
import SigninPage from "./views/SigninPage/SigninPage";
import {isAuthorized} from "./store/Atoms";
import PrivacyPolicyPage from "./views/PrivacyPolicyPage/PrivacyPolicyPage";
import RecoveryPage from "./views/RecoveryPage/RecoveryPage";
import ResetPasswordPage from "./views/ResetPasswordPage/ResetPasswordPage";

export default function BasicRoutes() {
  const [loading, setLoading] = useState(true);

  const InitializeAuth = () => {
    const setIsAuth = useSetRecoilState(isAuthorized);

    useEffect(() => {
      const checkAuth = async () => {
        try {
          const res = await axiosInstance.get('/authorized/');
          if (res.status === 201) {
            setIsAuth(true);
            console.log('logged in (app router)')
          } else {
            setIsAuth(false);
            console.log('logged out (app router)')
          }
        } catch (error) {
          setIsAuth(false);
        }
        setLoading(false);
      }
      checkAuth();
    }, [setIsAuth]);

    return null;
  }

  const ReportWrapper = () => {
    const isAuth = useRecoilValue(isAuthorized);
    return (
      <>
        { (isAuth && !loading) ? (
          <>
            <AppHeader />
            <ReportsPage />
          </>
        ) : !loading && (
          <div className="loader-wrapper">
            <span>Not Authorized, please <Link to="/sign-in">Sign In</Link> to continue.</span>
          </div>
        )}
      </>
    )
  }

  const HomeWrapper = () => {
    const isAuth = useRecoilValue(isAuthorized);
    return (
      <>
        { (isAuth && !loading) ? (
          <HomePage isAuth />
        ) : !loading && (
          <HomePage />
        )}
      </>
    )
  }

  return (
    <RecoilRoot>
      <InitializeAuth />
      <Router>
        <Switch>
          <Route exact path="/">
            <HomeWrapper />
          </Route>
          <Route exact path="/explore">
            <>
              <AppHeader />
              <PlatformPage />
            </>
          </Route>
          <Route exact path="/report">
            <ReportWrapper />
          </Route>
          <Route exact path="/sign-in">
            <SigninPage />
          </Route>
          <Route exact path="/sign-up">
            <SignupPage />
          </Route>
          <Route exact path="/recovery">
            <RecoveryPage />
          </Route>
          <Route exact path="/recovery/reset-password/:resetToken">
            <ResetPasswordPage />
          </Route>
          <Route exact path="/privacy-policy">
            <PrivacyPolicyPage />
          </Route>
        </Switch>
        { loading && (
          <div className="loader-wrapper">
            <CircularProgress style={{color: '#50F36A'}} />
          </div>
        )}
        <GlobalMessage />
      </Router>
    </RecoilRoot>
  );
}
