/* eslint-disable */
import React from "react";
import { useRecoilValue } from "recoil";
import { fetchedReportCredits } from "../store/Selectors";
import LinedTable from "./LinedTable";


export default function ReportCredits() {
  const credits = useRecoilValue(fetchedReportCredits);

  return <LinedTable data={credits} />;
}
