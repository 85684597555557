/* eslint-disable */
import React from 'react';
import {useRecoilState} from "recoil";
import {transTypeFilters} from "../store/Atoms";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import GreenCheckbox from "./GreenCheckbox";

export default function TransTypeFilter() {
  const [state, setState] = useRecoilState(transTypeFilters);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const { Rel, Wdr } = state;

  return (
    <div>
      <FormControl component="fieldset">
        <FormGroup>
          <FormControlLabel
            control={<GreenCheckbox checked={Rel} onChange={handleChange} name="Rel" />}
            label="Rel"
          />
          <FormControlLabel
            control={<GreenCheckbox checked={Wdr} onChange={handleChange} name="Wdr" />}
            label="Wdr"
          />
        </FormGroup>
      </FormControl>
    </div>
  )
}
