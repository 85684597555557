/* eslint-disable */
import React, { useState } from "react";
import { Bar, ComposedChart, Line, Tooltip, XAxis, YAxis } from "recharts";
import { useRecoilValue } from "recoil";
import { fetchedSupplyPanel } from "../store/Selectors";
import SupplyTickers from "./SupplyTickers";

export default function SupplyPanelChart() {
  const data = useRecoilValue(fetchedSupplyPanel);
  const [tickers, setTickers] = useState(null);

  if (!data) return null;

  if (data.length === 0) {
    return (
      <div
        style={{
          background: "rgba(80, 243, 106, 0.02)",
          height: 156,
          width: "100%",
          marginTop: 16,
        }}
      />
    );
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload) {
      const values = {
        rel: payload[0].value,
        wdr: payload[1].value,
        net: payload[2].value,
      };

      if (JSON.stringify(values) !== JSON.stringify(tickers)) {
        setTickers(values);
      }

      return (
        <div style={{}}>
          <p style={{ color: "#50F36A", fontSize: "12px" }}>{payload[0].payload.name}</p>
        </div>
      );
    } else {
      setTickers(null);
    }

    return null;
  };

  return (
    <>
      <SupplyTickers tickers={tickers} />
      <ComposedChart
        width={615}
        height={300}
        data={data}
        barSize={8}
        stackOffset="sign"
        margin={{ top: 16, right: 4, left: 4, bottom: 0 }}
        style={{ background: "rgba(80, 243, 106, 0.02)", marginTop: 16 }}
      >
        <XAxis dataKey="name" height={60} padding={{ left: 10 }} tick={{ fontSize: 12 }} />
        <YAxis orientation="right" label={{ position: "right" }} tick={{ fontSize: 12 }} />
        <Bar dataKey="Rel" fill="#50F36A" stackId="stack" />
        <Bar dataKey="Wdr" fill="#FFFFFF" stackId="stack" />
        <Tooltip position={{ y: -10 }} content={<CustomTooltip />} />
        <Line type="monotone" dot={false} strokeWidth={2} dataKey="Net" stroke="#FFFFFF" />
      </ComposedChart>
    </>
  );
}
