import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import InputField from './InputField';
import axiosInstance from '../utils/axiosApi';
import useStyles from './BetaTesterForm.style';


const RecoverUsernameSchema = Yup.object().shape({
  email: Yup.string().email().required('Required'),
})


const RecoverUsernameForm = () => {
  const classes = useStyles();
  const [submitted, setSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const formFields = {email: ''};

  const handleSubmit = async (values) => {
    try {
      // Any non 2XX status code will be caught as an error
      await axiosInstance.post('/recover/username/', values);
      setSubmitted(true);
      setSubmitError(null);
    } catch (error) {
      if (error.request.status === 404) {
        setSubmitError("An account with that email was not found.");
      } else {
        setSubmitError("There has been an error recovering your username.");
      }
    }
  }

  return (
    <>
      {!submitted && (
        <Formik
          initialValues={formFields}
          validationSchema={RecoverUsernameSchema}
          onSubmit={handleSubmit}
        >
          {({errors, touched}) => (
            <Form>
              <section className={classes.form}>
                <InputField
                  name='email'
                  label='Email'
                  className={classes.inputField}
                  error={errors.email && touched.email}
                  helperText={errors.email}
                />
              </section>
              <div className={classes.submitWrapper}>
                <Button className={classes.submitBtn} type='submit'>Recover Username</Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
      <div className={classes.descriptionWrapper}>
        {submitted && (
          <p>An email has been sent with your username.</p>
        )}
      </div>
      <div className={classes.errorWrapper}>
        {submitError && (
          <p>{submitError}</p>
        )}
      </div>
    </>
  )
};

export default RecoverUsernameForm;
