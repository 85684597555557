import {useRecoilValue} from "recoil";
import React, {useEffect, useState} from "react";
import {Layer, Source} from "react-map-gl";
import {activeBankServiceArea} from "../store/Atoms";
import {activeServiceAreaFill} from "../map-style";

function ActiveBankServiceArea({ mapRef }) {
  const active = useRecoilValue(activeBankServiceArea);
  const [stored, setStored] = useState(null);

  useEffect(() => {
    const mapController = mapRef.current.getMap();
    if (active && active.service_area) {
      const newParsed = JSON.parse(active.service_area);
      mapController.getSource('active-service-area').setData(newParsed);
      if (!stored) setStored(newParsed);
    } else if (stored) {
      mapController.getSource('active-service-area').setData({
        'type': 'FeatureCollection',
        'features': []
      });
    }
    // eslint-disable-next-line
  }, [active]);

  return (
    <Source
      id="active-service-area"
      type="geojson"
      data={stored}
    >
      <Layer id="active-service-area-fill" {...activeServiceAreaFill} />
    </Source>
  )
}

export default ActiveBankServiceArea;
