/* eslint-disable */
import React, { Suspense } from "react";
import { Button } from "@material-ui/core";
import useStyles from "./SupplyPanel.styles";
import Skeleton from "@material-ui/lab/Skeleton";
import SupplyPanelChart from "./SupplyPanelChart";
import DateFilterBar from "./DateFilterBar";
import { useRecoilState } from "recoil";
import { supplyPanelActionFilter, supplyPanelDateFilter } from "../store/Atoms";
import cx from "classnames";
import SupplyCreditFilter from "./SupplyCreditFilter";

export default function SupplyPanel() {
  const classes = useStyles();
  const [dateFilter, setDateFilter] = useRecoilState(supplyPanelDateFilter);

  return (
    <section style={{ padding: 24 }}>
      <h3 className={classes.h3}>Market Overview</h3>
      <SupplyCreditFilter />
      <line
        x1="40"
        x2="260"
        y1="100"
        y2="100"
        stroke="#5184AF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray=".001, 30"
      />
      <Suspense fallback={<Skeleton animation="wave" variant="rect" height={300} style={{ marginTop: 40 }} />}>
        <SupplyPanelChart />
      </Suspense>
      <DateFilterBar filter={dateFilter} setFilter={setDateFilter} variation="market-overview" />
    </section>
  );
}
