import React, { useEffect, Suspense, useState } from "react";
import {useSetRecoilState} from "recoil";
import qs from "qs";
import useStyles from "./ReportView.styles";
import { reportParams } from "../store/Atoms";
import ReportSupply from "./ReportSupply";
import ReportCredits from "./ReportCredits";
import ReportAppBar from "./ReportAppBar";
import ReportSideNav from "./ReportSideNav";
import TransPagination from "./TransPagination";
import TransactionFilter from "./TransactionFilter";
import ReportTransactions from "./ReportTransactions";

function ReportView() {
  const classes = useStyles();
  const setParams = useSetRecoilState(reportParams);
  const [init, setInit] = useState(false);
  const { search } = window.location;
  setParams(qs.parse(search, { ignoreQueryPrefix: true }));

  useEffect(() => {
    if (!init) {
      document.querySelector("body").classList.add("report");
      setInit(true);
    }
    return () => {
      document.querySelector("body").classList.remove("report");
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ReportAppBar />
      <div style={{ display: "flex", backgroundColor: "#191919", justifyContent: "center", paddingTop: 56, paddingBottom: 56 }}>
        <article className="report-view">
          <div className="report-view__container">
            <h1 className={classes.h1}>Detailed Report</h1>
            <div className={classes.divider} />
            <ReportSupply />
            <h3 className={classes.h3}>Credits</h3>
            <Suspense fallback={<div>Loading...</div>}>
              <ReportCredits />
            </Suspense>
            <div className="flex flex--align-center flex--justify-sb" style={{ marginTop: 40, paddingBottom: 16 }}>
              <h3 className={classes.h3} style={{ color: "white", padding: 0 }}>
                Transactions
              </h3>
              <Suspense fallback={null}>
                <div className="flex flex--align-center">
                  <TransPagination variation="report" />
                  <TransactionFilter filterType="report-popper" />
                </div>
              </Suspense>
            </div>
            <div className={classes.divider} />
            <div style={{minHeight: 676}}>
              <Suspense fallback={<div>Loading...</div>}>
                <ReportTransactions />
              </Suspense>
            </div>
          </div>
        </article>
        <div>
          <ReportSideNav />
        </div>
      </div>
    </>
  );
}

export default ReportView;
