import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.black4,
  },
  box: {
    padding: "56px",
  },
  close: {
    position: "absolute",
    right: 56,
    top: 56,
  },
  smTitle: {
    fontWeight: 700,
    paddingLeft: "8px",
    opacity: "0.8",
  },
  h1: {
    fontFamily: "Space Grotesk",
    fontWeight: 700,
    fontSize: "32px",
    lineHeight: "40px",
    padding: "16px 0px 16px 0px",
    maxWidth: "501px",
  },
  h3: {
    fontFamily: "Space Grotesk",
    fontSize: "24px",
    fontWeight: "700",
    padding: "40px 0px 16px 0px",
    borderTop: "1px dashed gray",
    borderBottom: "1px dashed gray",
  },
  divider: {
    borderBottom: "1px dashed gray",
    width: "100%",
  },
  detailColumn: {
    width: "346px",
  },
  detailItem: {
    display: "flex",
    flexDirection: "column",
    height: "78px",
    borderBottom: "1px dashed gray",
  },
  boldItem: {
    fontWeight: "700",
    paddingTop: "16px",
  },
  p: {
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    opacity: "0.8",
    padding: "8px 0px 12px 0px",
  },
}));
