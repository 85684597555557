import makeStyles from "@material-ui/core/styles/makeStyles";
// import merge from "lodash.merge";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.black4,
    color: "white",
    padding: "24px 24px 64px 24px",
  },

  h3: {
    fontFamily: "Space Grotesk",
    fontSize: "24px",
    fontWeight: "700",
  },

  ul: {
    paddingTop: "16px",
  },
  li: {
    listStyle: "none",
    fontFamily: "Source Sans Pro",
    fontWeight: "400",
    borderTop: "1px dashed gray",
    '&:first-child': {
      borderTop: 'none'
    }
  },
  bankTop: {
    paddingTop: "16px",
    display: "flex",
    justifyContent: "space-between",
  },
  bankData: {
    display: "flex",
    flexDirection: "row",
    padding: "8px 0px 16px 0px",
    fontWeight: "600",
    "& p": {
      paddingRight: "16px",
      "& img": {
        paddingRight: "8px",
      },
    },
  },
  bankTitle: {
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
}));
