import {makeStyles} from "@material-ui/core/styles";

// eslint-disable-next-line no-unused-vars
export default makeStyles((theme) => ({
  container: {
    position: 'absolute',
    top: '35px'
  },
  paper: {
    width: '100%',
    padding: 16,
    minWidth: 347,
    backgroundColor:  '#191919',
    border: '1px solid #030303',
    height:' 100%'
  },
  button: {
    fontSize: "13px",
    background: "transparent",
    width: '100%',
    justifyContent: 'flex-start',
    "& > .MuiButton-label": {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      "& > small": {
        marginLeft: 8,
      }
    },
    "&:hover": {
      background: "#323432",
    },
  },
  title: {
    fontSize: 14,
    marginBottom: 8
  }
}));
