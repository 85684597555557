/* eslint-disable */
import makeStyles from "@material-ui/core/styles/makeStyles";
import merge from "lodash.merge";

export default makeStyles(() => ({
  root: {
    background: '#191919',
  },
  container: {
    minHeight: '100vh',
    maxWidth: 1440,
    margin: '0 auto',
    padding: '56px 96px',
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  headingWrapper: {
    marginTop: 96,
  },
  headingFont: {
    width: '78%',
    color: 'white',
    fontSize: '6vw',
    letterSpacing: '-0.02em',
    '@media (min-width: 1800px)': {
      fontSize: 92,
    }
  },
  descriptionWrapper: {
    marginTop: 70
  },
  descriptionFont: {
    color: 'white',
    letterSpacing: '-0.02em',
    fontSize: 16,
  },
  logoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
    fontSize: 12,
    letterSpacing: '0.2px',
  }
}));
