import React from 'react';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import GreenCheckbox from "./GreenCheckbox";
import useStyles from "./ReportSideNav.style";

const defaultOptions = [
  { option: 'all', optionTitle: 'All' },
  { option: 'none', optionTitle: 'None' },
];

function BulkSelect({
  selected,
  handleCheckbox,
  handleMenuSelection,
}) {
  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={classes.bulkSelectControl}>
      <GreenCheckbox
        className={classes.bulkCheckbox}
        checked={selected === 'all'}
        name='selector'
        onChange={handleCheckbox}
        indeterminate={selected === 'indeterminate'}
      />
      <Select value="" onChange={handleMenuSelection}>
        <MenuItem value="">Select</MenuItem>
        { defaultOptions.map((option) => (
          <MenuItem key={option.option} value={option.option}>
            {option.optionTitle}
          </MenuItem>
        )) }
      </Select>
    </FormControl>
  )
}

export default BulkSelect;
