import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {useEffect} from "react";
import {FlyToInterpolator} from "react-map-gl";
import {activeDrawFeatures, mapViewport, selectedSearchLocation} from "../store/Atoms";
import {randomString} from "../utils";

export default function LocationDropper({ mapRef, editorRef }) {
  const [viewport, setViewport] = useRecoilState(mapViewport);
  const selectedLocation = useRecoilValue(selectedSearchLocation);
  const setActiveFeatures = useSetRecoilState(activeDrawFeatures);

  useEffect(() => {
    if (selectedLocation) {
      setViewport({
        ...viewport,
        longitude: parseFloat(selectedLocation.long),
        latitude: parseFloat(selectedLocation.lat),
        zoom: 8,
        transitionInterpolator: new FlyToInterpolator({speed: 1.2}),
        transitionDuration: 1000
      });

      if (selectedLocation.type && selectedLocation.type === 'huc') {
        setTimeout(() => {
          if (mapRef && mapRef.current) {
            const map = mapRef.current.getMap();
            const point = map.project([parseFloat(selectedLocation.long), parseFloat(selectedLocation.lat)]);
            const features = mapRef.current.queryRenderedFeatures(
              point,
              { layers: ['huc08-fill'] }
            );
            if (features.length > 0) {
              editorRef.current.addFeatures(features[0])
              const data = [...editorRef.current.getFeatures(), features[0]];
              const geomSet = data.map((item) => JSON.stringify(item.geometry));
              setActiveFeatures({ features: geomSet, seed: randomString() });
            }
          }
        }, 1000);
      } else {
        const newFeatures = [
          {
            type: "Feature",
            properties:{},
            geometry: {
              coordinates: [
                parseFloat(selectedLocation.long),
                parseFloat(selectedLocation.lat)
              ],
              type: "Point"
            }
          }
        ]
        editorRef.current.addFeatures(newFeatures[0])
        const data = [...editorRef.current.getFeatures(), ...newFeatures];
        const geomSet = data.map((item) => JSON.stringify(item.geometry));
        setActiveFeatures({ features: geomSet, seed: randomString() });
      }
    }
  // eslint-disable-next-line
  }, [selectedLocation]);

  return null;
}
