import React from "react";
import { Link, Redirect } from "react-router-dom";
import useStyles from "./HomePage.styles";
import LocationSearch from '../../components/LocationSearch';
import { ReactComponent as Logo } from "../../assets/images/logo.svg";

export default ({ isAuth }) => {
  const classes = useStyles();

  const body = document.querySelector('body');
  body.classList.add('scroll');

  // redirect from '/' to '/explore' if the user is logged in
  if (isAuth) {
    return <Redirect to='/explore' />;
  }

  return (
    <div className={classes.root} style={{
      backgroundImage: `url("./background-main.png")`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    }}>
      <div className={classes.containerColumn}>
        <div className={classes.headingWrapper}>
          <Logo style={{height: 48}}  className={classes.headingLogo}/>
          <h1 className={classes.headingFont}>
            Environmental markets begin here.
          </h1>
        </div>
        <div className={classes.headingWrapper}>
          <div className='location_wrapper'>
            <LocationSearch className={classes.locationSearch}/>
          </div>
        </div>
        <div className={classes.homepageDescriptionWrapper}>
          <p className={classes.descriptionFont}>
            Have an account?
          </p>
          <Link className={classes.descriptionLink} style={{ textDecoration: 'none' }} to="/sign-in">
            Sign In
          </Link>
        </div>
      </div>
    </div>
  );
};
