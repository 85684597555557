import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./AppTheme";
import AppRouter from "./AppRouter";

export default () => (
  <>
    <ThemeProvider theme={theme}>
      <AppRouter />
    </ThemeProvider>
  </>
);
