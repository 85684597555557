/* eslint-disable */
import React, {Suspense} from "react";
import cx from 'classnames';
import { Button } from "@material-ui/core";
import makeStyles from "./PermitsPanel.styles";
import PermitsPanelChart from "./PermitsPanelChart";
import { useRecoilState } from "recoil";
import { permitPanelDateFilter, permitPanelActionFilter } from "../store/Atoms";
import DateFilterBar from "./DateFilterBar";
import Skeleton from '@material-ui/lab/Skeleton';

export default function PermitsPanel() {
  const classes = makeStyles();
  const [actionFilter, setActionFilter] = useRecoilState(permitPanelActionFilter);
  const [dateFilter, setDateFilter] = useRecoilState(permitPanelDateFilter);

  return (
    <section style={{ padding: 24 }}>
      <h3 className={classes.h3}>Permits</h3>
      <div style={{ padding: "14px 0px 14px 0px", borderBottom: "1px dashed grey" }}>
        <Button
          onClick={() => setActionFilter([])}
          className={cx(classes.tiny, { active: actionFilter.length === 0})}
        >
          All
        </Button>
        <Button
          onClick={() =>setActionFilter(['Action'])}
          className={cx(classes.tiny, { active: actionFilter.includes('Action') })}
        >
          Action
        </Button>
        <Button
          onClick={() => setActionFilter(['Impact'])}
          className={cx(classes.tiny, { active: actionFilter.includes('Impact') })}
        >
          Impact
        </Button>
        <Button
          onClick={() => setActionFilter(['Mitigation'])}
          className={cx(classes.tiny, { active: actionFilter.includes('Mitigation') })}
        >
          Mitigation
        </Button>
      </div>
      <Suspense fallback={<Skeleton animation="wave" variant="rect" height={300} style={{ marginTop: 40 }} />}>
        <PermitsPanelChart />
      </Suspense>
      <DateFilterBar filter={dateFilter} setFilter={setDateFilter} />
    </section>
  );
}
