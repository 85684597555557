import React from 'react';
import { Popup } from "react-map-gl";

function PermitPopup({ popupInfo, setPopupInfo }) {

  if (!popupInfo) return null;

  return (
    <Popup
      tipSize={5}
      anchor="bottom"
      longitude={popupInfo.longitude}
      latitude={popupInfo.latitude}
      closeOnClick={false}
      onClose={setPopupInfo}
    >
      <pre>
        {popupInfo.type}: {popupInfo.name}
      </pre>
      {popupInfo.work_type && (
        <pre>{popupInfo.work_type}</pre>
      )}
      {popupInfo.type !== 'Bank' && (
        <pre>Mitigation required: {popupInfo.compensatory_mitigation_required}</pre>
      )}
    </Popup>
  );
}

export default PermitPopup;
