/* eslint-disable */
import React, { Suspense } from "react";
import qs from "qs";
import { useRecoilValue } from "recoil";
import Slide from "@material-ui/core/Slide";
import { useHistory } from "react-router-dom";
import { activeBanks, activeHucs, activeDrawFeatures } from "../store/Atoms";
import BanksPanel from "./BanksPanel";
import SupplyPanel from "./SupplyPanel";
import PermitsPanel from "./PermitsPanel";
import Button from '@material-ui/core/Button';
import { ReactComponent as ArrowFwd } from "../assets/images/arrow-fwd.svg";
import HucSelector from "./HucSelector";
import makeStyles from "@material-ui/core/styles/makeStyles";

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  button: {
    fontFamily: "Space Grotesk",
    color: "white",
    borderRadius: "44px",
    padding: "4px 12px 4px 12px",
    height: 24,
    fontSize: 14,
    "&:hover": {
      backgroundColor: "#323432",
    },
    "&.active": {
      backgroundColor: "#282A28",
    },
    "&:disabled": {
      opacity: "0.3",
      color: "#FFFFFF",
    },
  },
}));

function SidePanel() {
  const classes = useStyles();
  let history = useHistory();
  const hucs = useRecoilValue(activeHucs);
  const actives = useRecoilValue(activeBanks);
  const { banks } = actives;
  const drawFeatures = useRecoilValue(activeDrawFeatures);

  return (
    <Slide direction="right" in={drawFeatures && drawFeatures.features.length > 0} mountOnEnter unmountOnExit>
      <aside className="side-panel">
        <section className="active-huc" style={{ justifyContent: 'flex-end' }}>
          {/*<HucSelector />*/}
          <Button
            className={classes.button}
            endIcon={<ArrowFwd />}
            onClick={(e) => {
              e.preventDefault();
              history.push(`/report?${qs.stringify({ hucs: hucs, banks: banks }, { encode: false, indices: false })}`);
            }}
          >
            Detailed Report
          </Button>
        </section>
        <Suspense fallback={<div>Loading...</div>}>
          <SupplyPanel />
        </Suspense>
        <Suspense fallback={<div>Loading...</div>}>
          <PermitsPanel />
        </Suspense>
        <Suspense fallback={<div>Loading...</div>}>
          <BanksPanel />
        </Suspense>
      </aside>
    </Slide>
  );
}

export default SidePanel;
