import makeStyles from "@material-ui/core/styles/makeStyles";

// eslint-disable-next-line no-unused-vars
export default makeStyles((theme) => ({
  inputField: {
    width: '100%',
    minHeight: 91,
    '& > .MuiFormLabel-root': {
      color: 'rgba(255, 255, 255, 0.8)',
      fontSize: 24,
    },
    '& > .MuiFormLabel-root.Mui-error': {
      color: '#f44336'
    },
    '& > .MuiInput-underline:before': {
      borderColor: 'rgba(255, 255, 255, 0.8)'
    },
    '& > .MuiInputBase-root > .MuiInputBase-input': {
      paddingBottom: 12,
      paddingTop: 12,
      fontSize: 24,
    },
    '& > .MuiInputBase-root > .MuiSelect-icon': {
      color: 'rgba(255, 255, 255, 0.8)',
    }
  },
  form: {
    display: 'grid',
    gridGap: '56px 42px',
    '@media (min-width: 600px)': {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    marginTop: 32
  },
  submitWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  submitBtn: {
    backgroundColor: "#50F36A",
    fontFamily: "Source Sans Pro",
    fontWeight: 'bold',
    fontSize: 24,
    lineHeight: '108%',
    textTransform: "none",
    color: "#191919",
    borderRadius: "44px",
    height: "58px",
    padding: "16px 24px 16px 24px",
    marginTop: 64,
    "&:hover": {
      backgroundColor: "#AEF9BA",
      color: "#191919",
    },
    "&:active": {
      backgroundColor: "#8DF79E",
      color: "#191919",
    },
    "&:focus": {
      backgroundColor: "#50F36A",
      color: "#191919",
    },
    "&:disabled": {
      opacity: "0.2",
      color: "#191919",
    },
  },
  descriptionWrapper: {
    marginTop: 35,
    marginBottom: 35,
    color: 'white',
    letterSpacing: '-0.02em',
    fontWeight: 500,
    fontSize: 20,
  },
  errorWrapper: {
    marginTop: 35,
    marginBottom: 35,
    color: 'red',
    letterSpacing: '-0.02em',
    fontWeight: 500,
    fontSize: 20,
  },
}));
