/* eslint-disable */
import React from 'react';
import makeStyles from "./DateFilterBar.styles";
import {Button} from "@material-ui/core";
import cx from "classnames";
import moment from 'moment';

export default function DateFilterBar({ filter, setFilter, variation }) {
  const classes = makeStyles();

  const handleOnClick = (type) => {
    let values = {
      type: type,
      to: moment().format('YYYY-MM-DD'),
    }

    switch (type) {
      case '1d':
        values.from = moment().format('YYYY-MM-DD');
        break;
      case '1w':
        values.from = moment().subtract(7,'d').format('YYYY-MM-DD');
        break;
      case '1m':
        values.from = moment().subtract(1, 'months').format('YYYY-MM-DD');
        break;
      case '3m':
        values.from = moment().subtract(3, 'months').format('YYYY-MM-DD');
        break;
      case '6m':
        values.from = moment().subtract(6, 'months').format('YYYY-MM-DD');
        break;
      case '1y':
        values.from = moment().subtract(1, 'years').format('YYYY-MM-DD');
        break;
      case '2y':
        values.from = moment().subtract(2, 'years').format('YYYY-MM-DD');
        break;
      case '3y':
        values.from = moment().subtract(3, 'years').format('YYYY-MM-DD');
        break;
      case '4y':
        values.from = moment().subtract(4, 'years').format('YYYY-MM-DD');
        break;
      case '5y':
        values.from = moment().subtract(5, 'years').format('YYYY-MM-DD');
        break;
      default:
        // silence
    }

    setFilter(values);
  }

    return (
      <div style={{ paddingTop: "12px" }}>
        <Button
          onClick={() => handleOnClick('6m')}
          className={cx(classes.xsBtn, {active: filter && filter.type === '6m'})}
        >
          6m
        </Button>
        <Button
          onClick={() => handleOnClick('1y')}
          className={cx(classes.xsBtn, {active: filter && filter.type === '1y'})}
        >
          1y
        </Button>
        <Button
          onClick={() => handleOnClick('2y')}
          className={cx(classes.xsBtn, {active: filter && filter.type === '2y'})}
        >
          2y
        </Button>
        <Button
          onClick={() => handleOnClick('3y')}
          className={cx(classes.xsBtn, {active: filter && filter.type === '3y'})}
        >
          3y
        </Button>
        <Button
          onClick={() => handleOnClick('4y')}
          className={cx(classes.xsBtn, {active: filter && filter.type === '4y'})}
        >
          4y
        </Button>
         <Button
          onClick={() => handleOnClick('5y')}
          className={cx(classes.xsBtn, {active: filter && filter.type === '5y'})}
        >
          5y
        </Button>
        <Button
          onClick={() => setFilter(null)}
          className={cx(classes.xsBtn, {active: filter === null})}
        >
          All
        </Button>
      </div>
    )

}
