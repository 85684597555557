import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {numberWithCommas} from "../utils";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#191919",
    color: theme.palette.common.white,
    border: "none",
    fontSize: "12px",
    padding: "16px 0px 0px 0px",
  },
  body: {
    fontSize: 16,
    border: "none",
    padding: "16px 0px 0px 0px",
    opacity: "0.8",
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    overflowX: "none",
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    color: "white",
  },
});

export default function CustomizedTables({ data }) {
  const classes = useStyles();

  if (!data) return null;
  const { results } = data;

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">Date</StyledTableCell>
            <StyledTableCell align="left">Project</StyledTableCell>
            <StyledTableCell align="left">Credit Type</StyledTableCell>
            <StyledTableCell align="left">Trans. Type</StyledTableCell>
            <StyledTableCell align="right">Credits</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((row, index) => (
            <StyledTableRow key={row.name + index.toString()} style={{color: row.transaction_type === 'Rel' ? '#50F36A' : '#FF9494'}}>
              <StyledTableCell align="left">{row.transaction_date}</StyledTableCell>
              <StyledTableCell align="left" style={{maxWidth: 250, width: 250}}>{row.project_name}</StyledTableCell>
              <StyledTableCell align="left">{row.credit_types[0].name}</StyledTableCell>
              <StyledTableCell align="left">{row.transaction_type}</StyledTableCell>
              <StyledTableCell align="right" color="#50F36A">
                {row.transaction_type === 'Rel' ? '+' : '-'}
                {numberWithCommas(parseFloat(row.credits).toFixed(2))}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
