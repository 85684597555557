import React from 'react';
import Popper from '@material-ui/core/Popper';
import Button from '@material-ui/core/Button';
import PopupState, {bindPopper, bindToggle} from "material-ui-popup-state";
import Fade from "@material-ui/core/Fade";
import cx from 'classnames';
import useStyles from './BanksMapFilter.style';
import BanksStatusFilter from "./BanksStatusFilter";

export default function BanksMapFilter() {
  const classes = useStyles();
  return (
    <PopupState variant="popper" popupId="demo-popup-popper">
      {(popupState) => (
        <div style={{ marginLeft: 8 }}>
          <Button className={cx(classes.filterButton, classes.greenBtn)} {...bindToggle(popupState)}>
            Banks
          </Button>
          <Popper className={classes.popper} {...bindPopper(popupState)} transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={100}>
                <div>
                  <h4 className={classes.title}>Status Type</h4>
                  <BanksStatusFilter />

                </div>
              </Fade>
            )}
          </Popper>
        </div>
      )}
    </PopupState>
  )
}
