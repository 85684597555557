import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useRecoilState } from "recoil";
import {excludedBanks} from "../store/Atoms";
import IOSSwitch from "./IOSSwitch";

const useStyles = makeStyles({
  root: {
    marginRight: 0,
  },
});

export default function CustomizedSwitches({ item }) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    checked: true,
  });
  const [excludes, setExcludes] = useRecoilState(excludedBanks);

  const handleChange = (event) => {
    let newExcludes = [...excludes];

    if (event.target.checked) {
      // eslint-disable-next-line no-unused-expressions
      if (newExcludes.length > 1 && newExcludes.indexOf(item.id) > -1) {
         newExcludes.splice(newExcludes.indexOf(item.id), 1);
      } else {
        newExcludes = [];
      }
    } else {
      newExcludes.push(item.id);
    }

    setExcludes(newExcludes);
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <FormGroup>
      <FormControlLabel
        className={classes.root}
        control={<IOSSwitch checked={state.checked} onChange={handleChange} name="checked" />}
      />
    </FormGroup>
  );
}
