/* eslint-disable */
const fetchData = async (api, signal = null) => {
  if (!api) return undefined;
  let response = await fetch(api, { signal });
  if (response.status === 404) {
    return undefined;
  }
  response = await response.json();
  return response;
};

export {
  fetchData,
}
