/* eslint-disable */
import React from 'react';
import {banksStatusTypeFilters} from "../store/Atoms";
import {useRecoilState} from "recoil";
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import GreenCheckbox from "./GreenCheckbox";

export default function BanksStatusFilter() {
  const [state, setState] = useRecoilState(banksStatusTypeFilters);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const { Approved, Pending, Suspended, Terminated, Withdrawn } = state;

  return (
    <div>
      <FormControl component="fieldset">
        <FormGroup>
          <FormControlLabel
            control={<GreenCheckbox checked={Approved} onChange={handleChange} name="Approved" />}
            label="Approved"
          />
          <FormControlLabel
            control={<GreenCheckbox checked={Pending} onChange={handleChange} name="Pending" />}
            label="Pending"
          />
          <FormControlLabel
            control={<GreenCheckbox checked={state['Sold-Out']} onChange={handleChange} name="Sold-Out" />}
            label="Sold-Out"
          />
          <FormControlLabel
            control={<GreenCheckbox checked={Suspended} onChange={handleChange} name="Suspended" />}
            label="Suspended"
          />
          <FormControlLabel
            control={<GreenCheckbox checked={Terminated} onChange={handleChange} name="Terminated" />}
            label="Terminated"
          />
          <FormControlLabel
            control={<GreenCheckbox checked={Withdrawn} onChange={handleChange} name="Withdrawn" />}
            label="Withdrawn"
          />
        </FormGroup>
      </FormControl>
    </div>
  )
}
