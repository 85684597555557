import makeStyles from "@material-ui/core/styles/makeStyles";
// import merge from "lodash.merge";

// eslint-disable-next-line no-unused-vars
export default makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    background: '#191919',
    boxShadow: 'inset 0px -1px 0px #121212',
    height: 56,
    padding: '16px 32px',
    color: 'white',
    position: 'fixed',
    width: '100%',
    zIndex: 9,
  },
  btn: {
    width: 32,
    minWidth: 32,
    maxWidth: 32,
    height: 24,
    borderRadius: 44,
    padding: '4px 8px',
    marginRight: 12
  }
}));
