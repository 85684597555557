/* eslint-disable */
import React from 'react';
import { Field } from 'formik';
import TextField from '@material-ui/core/TextField';

const InputField = ({
  name,
  label,
  disabled = false,
  readOnly = false,
  value = null,
  options = [],
  autoComplete = '',
  autoFocus = false,
  className = '',
  styles = {},
  placeholder,
  required = false,
  error = false,
  helperText = '',
  type = '',
}) => (
        <Field name={name}>
          {({
            field,
            form: { setFieldValue },
          }) => (
            <TextField
              id={name}
              label={label}
              disabled={disabled}
              onChange={(event, newValue) => {
                const nextValue = newValue || '';
                setFieldValue(name, nextValue);
              }}
              inputProps={{
                readOnly,
              }}
              type={type}
              error={error}
              required={required}
              autoComplete={autoComplete}
              style={styles}
              helperText={helperText}
              className={className}
              {...field}
            />
          )}
        </Field>
);
export default InputField;
