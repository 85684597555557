/* eslint-disable */
function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function randomString() {
  return Math.random().toString(36).substring(7);
}

export {
  numberWithCommas,
  randomString
}
