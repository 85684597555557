import {useRecoilValue, useSetRecoilState} from "recoil";
import React, {useEffect, useState} from "react";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import GreenCheckbox from "./GreenCheckbox";
import {reportParams, reportExcludes} from "../store/Atoms";
import {fetchedReportBanks} from "../store/Selectors";
import useStyles from "./ReportSideNav.style";
import BulkSelect from "./BulkSelect";

Object.filter = (obj, predicate) =>
    Object.keys(obj)
          .filter( key => predicate(obj[key]) )
          // eslint-disable-next-line no-return-assign,no-sequences
          .reduce( (res, key) => (res[key] = obj[key], res), {} );

export default function ReportBanksFilter() {
  const classes = useStyles();
  const banks = useRecoilValue(fetchedReportBanks);
  // eslint-disable-next-line no-unused-vars
  const params = useRecoilValue(reportParams);
  const setExcludes = useSetRecoilState(reportExcludes);
  const [bankState, setBankState] = useState(null);
  const [init, setInit] = useState(false);
  const [selected, setSelected] = useState('all');
  const { results } = banks;
  const isChecked = () => selected !== 'none';

  useEffect(() => {
    const temp = {};
    if (results && results.length) {
      results.forEach((bank) => {
        temp[bank.id] = {
          name: bank.name,
          active: true,
        }
      });
      setBankState(temp);

      if (!init) setInit(true);
    }
  }, [init, results]);

  useEffect(() => {
    if (bankState && init) {
      const filtered = Object.filter(bankState, bank => !bank.active);
      const keys = Object.keys(filtered);

      if (keys.length === params.banks.length) {
        setSelected('none');
      } else if (keys.length) {
        setSelected('indeterminate');
      } else {
        setSelected('all');
      }

      setExcludes(keys);
    }
  }, [init, params, bankState, setExcludes]);

  const handleBankChange = (event, key) => {
    setBankState({
      ...bankState,
      [key]: {
        ...bankState[key],
        active: event.target.checked,
      }
    });
  };

  const handleSelection = (value) => {
    const keys = Object.keys(bankState);
    const temp = {};

    keys.forEach((k) => {
      temp[k] = { ...bankState[k], active: value }
    })

    setBankState(temp);
  }

  const handleCheckbox = () => {
    handleSelection(!isChecked());
  };

  const handleMenuSelection = (e) => {
    handleSelection(e.target.value === 'all');
  };

  return (
    <div className={classes.accordionDetails}>
      <div className={classes.bulkSelectWrapper}>
        <BulkSelect
          handleCheckbox={handleCheckbox}
          handleMenuSelection={handleMenuSelection}
          selected={selected}
        />
      </div>
      <FormControl style={{ marginTop: 12, marginLeft: 12 }} component="fieldset">
        <FormGroup>
          { bankState && Object.keys(bankState).map((k) => (
            <FormControlLabel
              control={
                <GreenCheckbox
                  checked={bankState[k].active}
                  onChange={(e) => handleBankChange(e, k)}
                  name={k}
                />
              }
              label={bankState[k].name}
            />
          ))}
        </FormGroup>
      </FormControl>
    </div>
  );
}
