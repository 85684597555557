import makeStyles from "@material-ui/core/styles/makeStyles";
// import merge from "lodash.merge";

// eslint-disable-next-line no-unused-vars
export default makeStyles((theme) => ({
  paper: {
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  filterButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontWeight: 500,
    textTransform: "none",
    borderRadius: "44px",
    height: "32px",
    marginRight: "8px",
    padding: "0px 16px 0px 16px",

  },

  greyBtn: {
    color: "#FFFFFF",
    backgroundColor: "#232423",
    "&:hover": {
      backgroundColor: "#323432",
    },
    "&.active": {
      backgroundColor: "#282A28",
    },
    "&:disabled": {
      opacity: "0.3",
      color: "#FFFFFF",
    },
  },

  greenBtn: {
    color: "#191919",
    backgroundColor: "#50F36A",
    "&:hover": {
      backgroundColor: "#AEF9BA",
    },
    "&:focus": {
      backgroundColor: "#AEF9BA",
    },
    "&.active": {
      backgroundColor: "#8DF79E",
    },
    "&:disabled": {
      opacity: "0.3",
    },
  },

  popper: {
    width: 162,
    background: '#191A19',
    border: '0.5px solid #050505',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.35)',
    borderRadius: 4,
    zIndex: 9999,
    padding: 16,
  },

  title: {
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: '100%',
    color: 'white',
    borderBottom: '1px dashed gray',
    paddingBottom: 16,
    marginBottom: 6
  }
}));
