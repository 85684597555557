import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import {useRecoilValue} from "recoil";
import {loadingServiceAreas} from "../store/Atoms";

function MapLoader() {
  const loading = useRecoilValue(loadingServiceAreas);

  if (!loading) return null;

  return (
    <div className="map-loader">
      <CircularProgress style={{color: '#50F36A'}} />
      <span style={{marginTop: 24}}>Aggregating Service Areas...</span>
    </div>
  )
}

export default MapLoader;
