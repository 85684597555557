/* eslint-disable */
import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { fetchedPermitsPanel } from "../store/Selectors";
import { Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";
import PermitTickers from "./PermitTickers";

export default function PermitsPanelChart() {
  const data = useRecoilValue(fetchedPermitsPanel);
  const [tickers, setTickers] = useState(null);

  if (!data) return null;

  if (data.length === 0) {
    return (
      <div
        style={{
          background: "rgba(80, 243, 106, 0.02)",
          height: 156,
          width: "100%",
          marginTop: 16,
        }}
      />
    );
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload) {
      const values = {
        total: payload[0].value,
      };

      if (JSON.stringify(values) !== JSON.stringify(tickers)) {
        setTickers(values);
      }

      return (
        <div style={{}}>
          <p style={{ color: "#50F36A", fontSize: "12px" }}>{payload[0].payload.name}</p>
        </div>
      );
    } else {
      setTickers(null);
    }

    return null;
  };

  return (
    <>
      <PermitTickers tickers={tickers} />
      <LineChart
        width={615}
        height={300}
        data={data}
        margin={{ top: 16, right: 4, left: 16, bottom: 4 }}
        style={{ background: "rgba(80, 243, 106, 0.02)", marginTop: 16 }}
      >
        <XAxis dataKey="name" label={{ angle: -90 }} tick={{ fontSize: 12 }} />
        <YAxis orientation="right" label={{ position: "right" }} tick={{ fontSize: 12 }} />
        <Tooltip position={{ y: -10 }} content={<CustomTooltip />} />
        <Line type="monotone" dataKey="count" stroke="#FFFFFF" strokeWidth={2} dot={false} />
      </LineChart>
    </>
  );
}
