/* eslint-disable */
import React, { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";
import { isAuthorized } from "../store/Atoms";

function AppHeader() {
  const isAuth = useRecoilValue(isAuthorized);

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.clear();
    window.location.href = '/';
  };

  return (
    <section className="app-header">
      <div className="app-header__center">
        <Logo />
        <span style={{ marginTop: 6 }}>
          The Environmental Credit Market Platform
        </span>
      </div>
      <div className="app-header__right">
        {isAuth ?
          <Button onClick={handleLogout} disableRipple>
            Sign Out
          </Button>
         :
          <Link to="/sign-in">
            Sign In
          </Link>
        }
      </div>
    </section>
  );
}

export default AppHeader;
