import React from "react";
import {withStyles} from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

const GreenCheckbox = withStyles({
  root: {
    padding: '0px 9px',
    color: "#545454",
    "&$checked": {
      color: "#50F36A",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default GreenCheckbox;
