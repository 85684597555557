import { selector } from 'recoil';
import qs from 'qs';
import {
  activeHucs,
  activeBanks,
  reportParams,
  bankDialogActive,
  permitPanelActionFilter,
  permitPanelDateFilter,
  excludedBanks,
  supplyPanelActionFilter,
  supplyPanelDateFilter,
  reportSupplyActionFilter,
  transactionPageIndex,
  transCreditTypeFilters,
  transTypeFilters,
  reportDateFilter,
  reportExcludes,
  activeDrawFeatures,
  banksStatusTypeFilters,
} from "./Atoms";
import axiosInstance from "../utils/axiosApi";

const fetchedBanks = selector({
  key: 'fetchedBanks',
  get: async ({get}) => {
    const hucs = get(activeHucs);
    const drawFeatures = get(activeDrawFeatures);
    const statusTypes = get(banksStatusTypeFilters);
    let params = { page_size: 10_000, timestamp: new Date().getTime() };
    const types = Object.keys(statusTypes);

    if ((!hucs || !hucs.length) && (!drawFeatures || !drawFeatures?.features?.length)) {
      return [];
    }

    if (hucs && hucs.length) {
      params = { ...params, active_hucs: hucs };
    }

    if (drawFeatures && drawFeatures?.features?.length) {
       params = { ...params, active_areas: drawFeatures.features };
    }

    params.status = types.filter((key) => statusTypes[key]);

    if (!params.status.length) return [];

    if (hucs || drawFeatures) {
      try {
        const response = await axiosInstance.get(`/banks/?${
          qs.stringify(params, { indices: false })
        }`);
        return response.data;
      } catch(error) {
        console.log("Error: ", JSON.stringify(error, null, 4));
        return [];
      }
    } else {
      return [];
    }
  },
});

const fetchedBanksUnion = selector({
  key: 'fetchedBanksUnion',
  get: async ({get}) => {
    const actives = get(activeBanks);
    const excludes = get(excludedBanks);
    const { banks } = actives;

    if (!banks || !banks.length) return null;

    const newBanks = banks.filter(item => !excludes.includes(item));
    try {
      const response = await axiosInstance.get(`/banks_geojson/?${
        qs.stringify({ banks: newBanks, timestamp: new Date().getTime() }, { indices: false })
      }`);
      if (response.data) {
        const geometry = JSON.parse(response.data.data.geometry);
        return {
          ...response.data,
          data: { ...response.data.data, geometry }
        };
      } return null;

    } catch (error) {
      console.log("Error: ", JSON.stringify(error, null, 4));
      return null;
    }

  },
});

const fetchedReportBanks = selector({
  key: 'fetchedReportBanks',
  get: async ({get}) => {
    const params = get(reportParams);

    if (params) {
      try {
        const response = await axiosInstance.get(`/banks/?${
          qs.stringify(params, { indices: false })
        }`);
        return response.data;
      } catch(error) {
        console.log("Error: ", JSON.stringify(error, null, 4));
        return [];
      }
    }

    return undefined;
  },
});

const fetchedPermits = selector({
  key: 'fetchedPermits',
  get: async ({get}) => {
    const hucs = get(activeHucs);
    const actives = get(activeBanks);
    const { banks } = actives;

    if ((!hucs || !hucs.length) && (!banks || !banks.length)) {
      return [];
    }

    let strippedHucs = [];
    if (hucs && hucs.length > 0) {
      strippedHucs = hucs.map(h => h.substring(1))
    }

    let values = {
      hucs: [...strippedHucs, ...hucs],
      page_size: 10_000,
      timestamp: new Date().getTime(),
    }

    if (banks || banks.length) {
      values = { ...values, active_banks: [...banks] };
    }

    // eslint-disable-next-line no-unused-vars
    const params = qs.stringify(values, { indices: false });

    try {
      const response = await axiosInstance.get(`/permits_geojson/?${params}`);
      return JSON.parse(response.data);
      // eslint-disable-next-line no-unreachable
    } catch (error) {
      console.log("Error: ", JSON.stringify(error, null, 4));
      return [];
    }
  },
});

const fetchedPermitsPanel = selector({
  key: 'fetchedPermitsPanel',
  get: async ({get}) => {
    const hucs = get(activeHucs);
    const actives = get(activeBanks);
    const { banks } = actives;
    const actions = get(permitPanelActionFilter);
    const dateRange = get(permitPanelDateFilter);

    if (!hucs) {
      return [];
    }

    let strippedHucs = [];
    if (hucs && hucs.length > 0) {
      strippedHucs = hucs.map(h => h.substring(1))
    }

    const values = {
      hucs: [...strippedHucs, ...hucs],
    }

    if (banks && banks.length > 0) {
      values.banks = banks;
    }

    if (actions && actions.length > 0) {
      values.actions = actions;
    }

    if (dateRange && dateRange.from) {
      values.from = dateRange.from;
    }

    if (dateRange && dateRange.to) {
      values.to = dateRange.to;
    }

    if (dateRange && dateRange.type) {
      values.date_range = dateRange.type;
    }

    const params = qs.stringify(values, { indices: false });

    try {
      const response = await axiosInstance.get(`/permits_panel/?${params}`);
      return response.data;
    } catch (error) {
      console.log("Error: ", JSON.stringify(error, null, 4));
      return [];
    }
  },
});

const fetchedSupplyPanel = selector({
  key: 'fetchedSupplyPanel',
  get: async ({get}) => {
    const actions = get(supplyPanelActionFilter);
    const dateRange = get(supplyPanelDateFilter);
    const actives = get(activeBanks);
    const excludes = get(excludedBanks);
    const { banks } = actives;

    if (!banks || !banks.length) return null;

    const values = {};

    const newBanks = banks.filter(item => !excludes.includes(item));
    if (newBanks.length > 0) {
      values.banks = newBanks;
    }

    if (actions && actions.length > 0) {
      values.credit_types = actions;
    }

    if (dateRange && dateRange.from) {
      values.from = dateRange.from;
    }

    if (dateRange && dateRange.to) {
      values.to = dateRange.to;
    }

    if (dateRange && dateRange.type) {
      values.date_range = dateRange.type;
    }

    const newValues = qs.stringify(values, { indices: false });

    try {
      const response = await axiosInstance.get(`/supply/?${newValues}`);
      return response.data;
    } catch (error) {
      console.log("Error: ", JSON.stringify(error, null, 4));
      return [];
    }
  },
});

const fetchedCreditTypes = selector({
  key: 'fetchedCreditTypes',
  // eslint-disable-next-line no-unused-vars
  get: async ({get}) => {
    try {
      const response = await axiosInstance.get(`/credit_types.json`);
      return response.data;
    } catch (error) {
      console.log("Error: ", JSON.stringify(error, null, 4));
      return [];
    }
  },
});

const fetchedReportTransactions = selector({
  key: 'fetchedReportTransactions',
  get: async ({get}) => {
    const params = get(reportParams);
    const page = get(transactionPageIndex);
    const creditTypes = get(transCreditTypeFilters);
    const transTypes = get(transTypeFilters);
    const dateRange = get(reportDateFilter);
    const excludes = get(reportExcludes);

    if (params) {
      const values = { ...params, page };
      const types = Object.keys(creditTypes);
      const transKeys = Object.keys(transTypes);

      if (dateRange && dateRange.from) {
        values.from = dateRange.from;
      }

      if (dateRange && dateRange.to) {
        values.to = dateRange.to;
      }

      if (dateRange && dateRange.type) {
        values.date_range = dateRange.type;
      }

      if (excludes && excludes.length > 0) {
        const tempBanks = params.banks;
        values.banks = tempBanks.filter(item => !excludes.includes(item));
      }

      values.credit_types = types.filter((key) => creditTypes[key]);
      values.transaction_types = transKeys.filter((key) => transTypes[key]);

      if (!values.banks.length) return { results: [] };

      try {
        const response = await axiosInstance.get(`/transactions/?${
          qs.stringify(values, { indices: false })
        }`);
        return response.data;
      } catch (error) {
        console.log("Error: ", JSON.stringify(error, null, 4));
        return [];
      }
    }
    return undefined;
  },
});

const fetchedReportCredits = selector({
  key: 'fetchedReportCredits',
  get: async ({get}) => {
    const params = get(reportParams);
    const dateRange = get(reportDateFilter);
    const excludes = get(reportExcludes);

    if (params) {
      const values = { ...params };

      if (dateRange && dateRange.from) {
        values.from = dateRange.from;
      }

      if (dateRange && dateRange.to) {
        values.to = dateRange.to;
      }

      if (dateRange && dateRange.type) {
        values.date_range = dateRange.type;
      }

      if (excludes && excludes.length > 0) {
        const tempBanks = params.banks;
        values.banks = tempBanks.filter(item => !excludes.includes(item));
      }

      if (!values.banks.length) return { results: [] };

      try {
        const response = await axiosInstance.get(`/credits/?${
          qs.stringify(values, { indices: false })
        }`);
        return response.data;
      } catch (error) {
        console.log("Error: ", JSON.stringify(error, null, 4));
        return [];
      }
    }

    return undefined;
  },
});

const fetchedReportSupply = selector({
  key: 'fetchedReportSupply',
  get: async ({get}) => {
    const params = get(reportParams);
    const actions = get(reportSupplyActionFilter);
    const dateRange = get(reportDateFilter);
    const excludes = get(reportExcludes);

    const values = { ...params };

    if (dateRange && dateRange.from) {
      values.from = dateRange.from;
    }

    if (dateRange && dateRange.to) {
      values.to = dateRange.to;
    }

    if (dateRange && dateRange.type) {
      values.date_range = dateRange.type;
    }

    if (actions && actions.length > 0) {
      values.credit_types = actions;
    }

    if (excludes && excludes.length > 0) {
      const tempBanks = params.banks;
      values.banks = tempBanks.filter(item => !excludes.includes(item));
    }

    if (!values.banks.length) return [];

    if (params) {
      try {
        const response = await axiosInstance.get(`/supply/?${
          qs.stringify(values, { indices: false })
        }`);
        return response.data;
      } catch (error) {
        console.log("Error: ", JSON.stringify(error, null, 4));
        return [];
      }
    }

    return undefined;
  },
});

const fetchedBankCredits = selector({
  key: 'fetchedBankCredits',
  get: async ({get}) => {
    const bank = get(bankDialogActive);

    if (bank) {
      try {
        const response = await axiosInstance.get(`/credits/?${
          qs.stringify({banks: [bank.id]}, { indices: false })
        }`);
        return response.data;
      } catch (error) {
        console.log("Error: ", JSON.stringify(error, null, 4));
        return [];
      }
    }

    return undefined;
  },
});

const fetchedBankTransactions = selector({
  key: 'fetchedBankTransactions',
  get: async ({get}) => {
    const bank = get(bankDialogActive);
    const page = get(transactionPageIndex);
    const creditTypes = get(transCreditTypeFilters);
    const transTypes = get(transTypeFilters);

    if (bank) {
      const values = {
        banks: [bank.id],
        page,
      }

      const types = Object.keys(creditTypes);
      values.credit_types = types.filter((key) => creditTypes[key]);

      const transKeys = Object.keys(transTypes);
      values.transaction_types = transKeys.filter((key) => transTypes[key]);

      try {
        const response = await axiosInstance.get(`/transactions/?${
          qs.stringify(values, { indices: false })
        }`);
        return response.data;
      } catch (error) {
        console.log("Error: ", JSON.stringify(error, null, 4));
        return [];
      }
    }

    return undefined;
  },
});

const fetchedBankDetails = selector({
  key: 'fetchedBankDetails',
  get: async ({get}) => {
    const bank = get(bankDialogActive);

    if (bank) {
      try {
        const response = await axiosInstance.get(`/banks/${bank.id}`);
        return response.data;
      } catch (error) {
        console.log("Error: ", JSON.stringify(error, null, 4));
        return [];
      }
    }

    return undefined;
  },
});

export {
  fetchedBanks,
  fetchedCreditTypes,
  fetchedReportTransactions,
  fetchedReportCredits,
  fetchedReportSupply,
  fetchedPermits,
  fetchedBankCredits,
  fetchedBankTransactions,
  fetchedPermitsPanel,
  fetchedSupplyPanel,
  fetchedReportBanks,
  fetchedBanksUnion,
  fetchedBankDetails,
}
