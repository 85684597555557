/* eslint-disabled */
import {RENDER_STATE} from 'react-map-gl-draw';

const huc08labels = {
  'id': 'huc08-labels',
  'type': 'symbol',
  'source': 'huc08-source',
  'source-layer': 'huc08',
  "minzoom": 7,
  'layout': {
    'text-field': [
      'format',
      ['get', 'name'],
      { 'font-scale': 1.1 },
      '\n',
      {},
      ['get', 'huc8'],
      {
        'font-scale': 0.8,
      }
    ],
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    "text-size": 12
  },
  'paint': {
    "text-color": '#50F36A',
  },
};

const huc08Lines = {
  'id': 'huc08-lines',
  'type': 'line',
  'source': 'huc08-source',
  'source-layer': 'huc08',
  "minzoom": 6,
  'layout': {
    'line-join': 'round',
    'line-cap': 'round'
  },
  'paint': {
    'line-color': '#50F36A',
    'line-width': 1,
    'line-dasharray': [2, 4],
  }
};

const huc08Fill = {
  'id': 'huc08-fill',
  'type': 'fill',
  'source': 'huc08-source',
  'source-layer': 'huc08',
  'layout': {},
  'paint': {
    'fill-color': '#50F36A',
    'fill-opacity': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      0.2,
      0
    ]
  }
};

const clusterLayer = {
  'id': 'clusters',
  'type': 'circle',
  'source': 'permits',
  'filter': ['has', 'point_count'],
  'paint': {
    // 'circle-color': ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],
    'circle-color': ['step', ['get', 'point_count'], '#fff', 100, '#fff', 750, '#fff'],
    'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
  }
};

const clusterCountLayer = {
  'id': 'cluster-count',
  'type': 'symbol',
  'source': 'permits',
  'filter': ['has', 'point_count'],
  'layout': {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 12
  }
};

const unclusteredPointLayer = {
  'id': 'unclustered-point',
  'type': 'circle',
  'source': 'permits',
  'filter': ['!', ['has', 'point_count']],
  'paint': {
    // 'circle-color': '#11b4da',
    'circle-color': '#fff',
    'circle-radius': 6,
    'circle-stroke-width': 1,
    'circle-stroke-color': '#fff'
  }
};

const serviceAreaLines = {
  'id': 'service-area-lines',
  'type': 'line',
  'source': 'service-area',
  'layout': {
    'line-join': 'round',
    'line-cap': 'round'
  },
  'paint': {
    'line-color': '#50F36A',
    'line-width': 1,
    'line-dasharray': [2, 4],
  }
};

const serviceAreaFill = {
  'id': 'service-area-fill',
  'type': 'fill',
  'source': 'service-area',
  'layout': {},
  'paint': {
    'fill-color': '#50F36A',
    'fill-opacity': 0.15
  }
};

const activeServiceAreaFill = {

  'id': 'active-service-area-fill',
  'type': 'fill',
  'source': 'active-service-area',
  'layout': {},
  'paint': {
    'fill-color': '#50F36A',
    'fill-opacity': 0.35
  }
};

// eslint-disable-next-line no-unused-vars
function getEditHandleStyle({ state }) {
  switch (state) {
    case RENDER_STATE.SELECTED:
    case RENDER_STATE.HOVERED:
    case RENDER_STATE.UNCOMMITTED:
      return {
        fill: 'rgb(251, 176, 59)',
        fillOpacity: 1,
        stroke: 'rgb(255, 255, 255)',
        strokeWidth: 2,
        r: 7
      };

    default:
      return {
        fill: 'rgb(251, 176, 59)',
        fillOpacity: 1,
        stroke: 'rgb(255, 255, 255)',
        strokeWidth: 2,
        r: 5
      };
  }
}

function getFeatureStyle({ state }) {
  switch (state) {
    case RENDER_STATE.SELECTED:
    case RENDER_STATE.HOVERED:
    case RENDER_STATE.UNCOMMITTED:
    case RENDER_STATE.CLOSING:
      return {
        stroke: 'rgb(251, 176, 59)',
        strokeWidth: 2,
        fill: 'rgb(251, 176, 59)',
        fillOpacity: 0.3,
        strokeDasharray: '4,2',
        r: 7,
      };

    default:
      return {
        stroke: 'rgb(60, 178, 208)',
        strokeWidth: 2,
        fill: 'rgb(60, 178, 208)',
        fillOpacity: 0.1,
        r: 5,
      };
  }
}

export {
  huc08Fill,
  huc08Lines,
  clusterLayer,
  clusterCountLayer,
  unclusteredPointLayer,
  serviceAreaLines,
  serviceAreaFill,
  activeServiceAreaFill,
  getEditHandleStyle,
  getFeatureStyle,
  huc08labels,
};
