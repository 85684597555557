/* eslint-disable */
import React from 'react';
import {transCreditTypeFilters} from "../store/Atoms";
import {useRecoilState} from "recoil";
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import GreenCheckbox from "./GreenCheckbox";

export default function TransCreditFilter() {
  const [state, setState] = useRecoilState(transCreditTypeFilters);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const { Stream, Wetland, Species, Nutrient } = state;

  return (
    <div>
      <FormControl component="fieldset">
        <FormGroup>
          <FormControlLabel
            control={<GreenCheckbox checked={Stream} onChange={handleChange} name="Stream" />}
            label="Stream"
          />
          <FormControlLabel
            control={<GreenCheckbox checked={Wetland} onChange={handleChange} name="Wetland" />}
            label="Wetland"
          />
          <FormControlLabel
            control={<GreenCheckbox checked={Species} onChange={handleChange} name="Species" />}
            label="Species"
          />
          <FormControlLabel
            control={<GreenCheckbox checked={Nutrient} onChange={handleChange} name="Nutrient" />}
            label="Nutrient"
          />
        </FormGroup>
      </FormControl>
    </div>
  )
}
