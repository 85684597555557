import React from "react";
import Button from "@material-ui/core/Button";
import {useRecoilState, useRecoilValueLoadable} from "recoil";
import cx from 'classnames';
import useStyles from "./FilterBar.styles";
import {permitMarkersActive} from "../store/Atoms";
import LocationSearch from "./LocationSearch";
import BanksMapFilter from "./BanksMapFilter";
import {fetchedPermits} from "../store/Selectors";

export default () => {
  const classes = useStyles();
  const [active, setActive] = useRecoilState(permitMarkersActive);
  const permits = useRecoilValueLoadable(fetchedPermits);

  return (
    <div className={classes.root}>
      <section className="filter-bar">
        <LocationSearch />
        <BanksMapFilter />
        <Button
          disabled={permits.state !== 'hasValue' || permits.contents.length < 0}
          onClick={() => setActive(!active)}
          className={cx(classes.filterButton, classes.whiteBtn, {active: !active})}
        >
          Permits
        </Button>
      </section>
    </div>
  );
};

// export default FilterBar;
