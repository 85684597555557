/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {useRecoilState, useRecoilValue} from "recoil";
import Pagination from '@material-ui/lab/Pagination';
import {fetchedBankTransactions, fetchedReportTransactions} from "../store/Selectors";
import {transactionPageIndex} from "../store/Atoms";

export default function TransPagination({ variation }) {
  const [page, setPage] = useRecoilState(transactionPageIndex);
  const [count,setCount] = useState(0);

  let transactions;
  if (variation === 'bank') {
    transactions = useRecoilValue(fetchedBankTransactions);
  } else if (variation === 'report') {
    transactions = useRecoilValue(fetchedReportTransactions);
  }


  useEffect(() => {
    if (transactions) {
      setCount(Math.max(transactions.count / 15));
    }
  }, [transactions]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <div>
     <Pagination
      count={count}
      page={page}
      onChange={handleChange}
      siblingCount={0}
      boundaryCount={0}
    />
    </div>
  )
}
