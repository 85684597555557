/* eslint-disable */
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import { useRecoilState } from "recoil";
import { permitDialogActive } from "../store/Atoms";
import "react-json-pretty/themes/monikai.css";
import useStyles from "./PermitDialog.styles";
import ribbonSvg from "../assets/images/ribbon.svg";
import { ReactComponent as Close } from "../assets/images/btn-close.svg";

function PermitDialog() {
  const [permitDialog, setPermitDialog] = useRecoilState(permitDialogActive);
  const classes = useStyles();

  if (!permitDialog) {
    return null;
  }

  return (
    <Dialog
      open={!!permitDialog}
      onClose={() => setPermitDialog(null)}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <IconButton onClick={() => setPermitDialog(null)} className={classes.close}>
        <Close />
      </IconButton>

      <p className="flex flex--align-center">
        <img className={classes.img} src={ribbonSvg} />
        <p className={classes.smTitle}>Impact</p>
      </p>
      <h1 className={classes.h1} style={{ color: "white" }}>
        {permitDialog.project_name}
      </h1>
      <p style={{ opacity: "0.8", paddingBottom: "40px" }}>
        {permitDialog.county},{permitDialog.state} |{permitDialog.huc_8}
      </p>
      <h3 className={classes.h3}>Details</h3>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className={classes.detailColumn}>
          <div className={classes.detailItem}>
            <p className={classes.boldItem}>ID</p>
            <p className={classes.p}>{permitDialog.huc_8}</p>
          </div>
          <div className={classes.detailItem}>
            <p className={classes.boldItem}>Action folder ID</p>
            <p className={classes.p}></p>
          </div>
          <div className={classes.detailItem}>
            <p className={classes.boldItem}>Impact ID</p>
            <p className={classes.p}>{permitDialog.impact_id}</p>
          </div>
          <div className={classes.detailItem}>
            <p className={classes.boldItem}>{permitDialog.district}</p>
            <p className={classes.p}></p>
          </div>
          <div className={classes.detailItem}>
            <p className={classes.boldItem}>Action</p>
            <p className={classes.p}>{permitDialog.action}</p>
          </div>
          <div className={classes.detailItem}>
            <p className={classes.boldItem}>PNN</p>
            <p className={classes.p}>{permitDialog.pnn}</p>
          </div>
          <div className={classes.detailItem}>
            <p className={classes.boldItem}>Compensatory mitigation required?</p>
            <p className={classes.p}>{permitDialog.compensatory_mitigation_required}</p>
          </div>
          <div className={classes.detailItem}>
            <p className={classes.boldItem}>Work Type</p>
            <p className={classes.p}>{permitDialog.work_type}</p>
          </div>
          <div className={classes.detailItem}>
            <p className={classes.boldItem}>Closure Method</p>
            <p className={classes.p}>{permitDialog.closure_method}</p>
          </div>
        </div>
        <div className={classes.detailColumn}>
          <div className={classes.detailItem}>
            <p className={classes.boldItem}>Year</p>
            <p className={classes.p}>{permitDialog.end_date}</p>
          </div>
          <div className={classes.detailItem}>
            <p className={classes.boldItem}>Action ID</p>
            <p className={classes.p}>{permitDialog.action_id}</p>
          </div>
          <div className={classes.detailItem}>
            <p className={classes.boldItem}>Mitigation ID</p>
            <p className={classes.p}>{permitDialog.mitigation_id}</p>
          </div>
          <div className={classes.detailItem}>
            <p className={classes.boldItem}>DA Number</p>
            <p className={classes.p}>{permitDialog.da_number}</p>
          </div>
          <div className={classes.detailItem}>
            <p className={classes.boldItem}>Action Type</p>
            <p className={classes.p}>{permitDialog.action_type}</p>
          </div>
          <div className={classes.detailItem}>
            <p className={classes.boldItem}>Permit Authority</p>
            <p className={classes.p}>{permitDialog.permit_authority}</p>
          </div>
          <div className={classes.detailItem}>
            <p className={classes.boldItem}>Mitigation Permittee responsible?</p>
            <p className={classes.p}>{permitDialog.mitigation_permittee_responsible}</p>
          </div>
          <div className={classes.detailItem}>
            <p className={classes.boldItem}>End Date</p>
            <p className={classes.p}>{permitDialog.end_date}</p>
          </div>
          <div className={classes.detailItem}>
            <p className={classes.boldItem}>County</p>
            <p className={classes.p}>{permitDialog.county}</p>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default PermitDialog;
