/* eslint-disable */
import {makeStyles} from "@material-ui/core/styles";
import React, {useEffect, useState} from "react";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import Button from "@material-ui/core/Button";
import Popper from "@material-ui/core/Popper";
import {activeHucs, selectedRegions, hucChangeTrigger} from "../store/Atoms";
import IOSSwitch from "./IOSSwitch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: 0,
  },
  btn: {
    fontWeight: 'bold',
    color: "white",
    opacity: 0.3,
    fontSize: 16,
    background: 'transparent',
  },
  popper: {
    width: 162,
    background: '#191A19',
    border: '0.5px solid #050505',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.35)',
    borderRadius: 4,
    zIndex: 9999,
    padding: 16,
  },
  label: {
    color: 'white',
    fontSize: 14,
  }
}));

Array.prototype.remove = function() {
  let what, a = arguments, L = a.length, ax;
  while (L && this.length) {
    what = a[--L];
    while ((ax = this.indexOf(what)) !== -1) {
      this.splice(ax, 1);
    }
  }
  return this;
};

export default function HucSelector() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const id = open ? 'simple-popper' : undefined;
  const [hucs, setHucs] = useRecoilState(activeHucs);
  const regions = useRecoilValue(selectedRegions);
  const [state, setState] = useState(null);
  const setTrigger = useSetRecoilState(hucChangeTrigger);

  useEffect(() => {
    let temp = {};
    if (regions) {
      const regionKeys = Object.keys(regions);
      regionKeys.forEach((huc) => temp[huc] = { checked: true })
      setState(temp);
    }
  }, [regions]);

  useEffect(() => {
    const sidebar = document.querySelector('.side-panel');
    if (anchorEl) {
      window.addEventListener('click', handleListener);
      if (sidebar) {
        sidebar.addEventListener('scroll', handleScrollListener);
      }
    } else {
      window.removeEventListener('click', handleListener);
      if (sidebar) {
        sidebar.removeEventListener('scroll', handleScrollListener);
      }
    }
  }, [open, anchorEl]);

  const handleScrollListener = (e) => {
    const popper = document.getElementById('simple-popper');
    if (popper) {
      setOpen(false);
      setAnchorEl(null);
    }
  };

  const handleListener = (e) => {
    const popper = document.getElementById('simple-popper');
    const trigger = document.getElementById('popper-btn');

    if (popper) {
      if (popper.contains(e.target) || trigger.contains(e.target)) {
        // Silence
      } else {
        setOpen(false);
        setAnchorEl(null);
      }
    }
  };

  const handleClick = (event) => {
    const el = anchorEl ? null : event.currentTarget;
    setOpen(Boolean(el));
    setAnchorEl(el);
  };

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: {
        checked: event.target.checked
      }
    });

    let newList = [...hucs];
    if (event.target.checked) {
      newList.push(event.target.name);
      setHucs(newList);
    } else {
      setHucs(newList.remove(event.target.name));
    }

    setTrigger(Math.random().toString(36).substring(7));
  };

  if (!state) {
    return null;
  }

  return (
    <div>
      <Button id='popper-btn' className={classes.btn} onClick={handleClick}>
        {hucs.length} HUCs
      </Button>
      <Popper id={id} open={open} anchorEl={anchorEl} className={classes.popper}>
        {Object.keys(state).map((huc, index) => (
          <div
            key={huc + index}
            style={{marginTop: index > 0 ? 8 : 0}}
            className="flex flex--align-center flex--justify-sb"
          >
            <span className={classes.label}>{huc}</span>
            <FormControlLabel
              className={classes.root}
              control={<IOSSwitch checked={state[huc]?.checked} onChange={handleChange} name={huc} />}
              label={null}
            />
          </div>
        ))}
      </Popper>
    </div>
  );
}
