/* eslint-disable */
import makeStyles from "@material-ui/core/styles/makeStyles";
import merge from "lodash.merge";

export default makeStyles(() => ({
  root: {
    background: '#191919',
  },
  containerColumn: {
    minHeight: '100vh',
    maxWidth: 1440,
    margin: '0 auto',
    padding: '56px 96px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    minHeight: '100vh',
    maxWidth: 1440,
    margin: '0 auto',
    padding: '56px 96px',
    display: 'flex',
    flexDirection: 'column',
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  loginButton: {
    fontSize: "16px",
    fontWeight: 500,
    textTransform: "none",
    color: "#50F36A",
    backgroundColor: "#232423",
    borderRadius: "44px",
    height: "32px",
    padding: "0px 16px 0px 16px",
    "& > .MuiButton-label": {
      fontFamily: "Source Sans Pro",
    },
    "&:hover": {
      backgroundColor: "#323432",
    },
    "&.active": {
      backgroundColor: "#282A28",
    },
    "&:disabled": {
      opacity: "0.3",
      color: "#FFFFFF",
    },
  },
  // locationWrapper: {
  //   background: 'red',
  // },
  // locationSearch: {
  //   backgroundColor: 'blue',
  // },
  headingLogo: {
    marginBottom: 48,
  },
  headingWrapper: {
    marginTop: 96,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headingFont: {
    width: '78%',
    color: 'white',
    fontSize: '6vw',
    textAlign: 'center',
    letterSpacing: '-0.02em',
    '@media (min-width: 1800px)': {
      fontSize: 88,
    }
  },
  descriptionWrapper: {
    marginTop: 70,
  },
  homepageDescriptionWrapper: {
    marginTop: 70,
    marginBottom: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '@media (min-width: 600px)': {
      flexDirection: 'row',
    }
  },
  descriptionFont: {
    color: 'white',
    letterSpacing: '-0.02em',
    fontWeight: 500,
    fontSize: 24,
  },
  descriptionLink: {
    color: "#50F36A",
    letterSpacing: '-0.02em',
    fontWeight: 500,
    fontSize: 24,
    marginTop: '8px',
    '@media (min-width: 600px)': {
      marginLeft: '8px',
      marginTop: '0px',
    }
  },
  helperFont: {
    color: 'white',
    letterSpacing: '-0.02em',
    fontWeight: 500,
    fontSize: 18,
  },
  logoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
    fontSize: 12,
    letterSpacing: '0.2px',
  }
}));
