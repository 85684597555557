import React from 'react';
import Popper from '@material-ui/core/Popper';
import Button from '@material-ui/core/Button';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import Fade from '@material-ui/core/Fade';
import TransCreditFilter from "./TransCreditFilter";
import TransTypeFilter from "./TransTypeFilter";
import useStyles from './TransactionFilter.style';

export default function TransactionFilter() {
  const classes = useStyles();

  return (
    <PopupState variant="popper" popupId="demo-popup-popper">
      {(popupState) => (
        <div style={{ marginLeft: 8 }}>
          <Button className={classes.button} {...bindToggle(popupState)}>
            Filter
          </Button>
          <Popper className={classes.popper} {...bindPopper(popupState)} transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={100}>
                <div>
                  <h4 className={classes.title}>Credit Type</h4>
                  <TransCreditFilter />
                  <h4 className={classes.title} style={{marginTop: 16}}>Transaction Type</h4>
                  <TransTypeFilter />
                </div>
              </Fade>
            )}
          </Popper>
        </div>
      )}
    </PopupState>
  )
}
