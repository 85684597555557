import makeStyles from "@material-ui/core/styles/makeStyles";
// import merge from "lodash.merge";

// eslint-disable-next-line no-unused-vars
export default makeStyles((theme) => ({
  paper: {
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    width: 55,
    height: 24,
    background: '#232423',
    borderRadius: 44,
    color: 'white',
    fontSize: 16,
    lineHeight: '16px',
    padding: '4px 12px',
    "&:hover": {
      backgroundColor: "#323432",
    },
    "&:active": {
      backgroundColor: "#282A28",
    },
    "&:focus": {
      backgroundColor: "#282A28",
    },
    "&:disabled": {
      opacity: "0.8",
      color: theme.palette.black3,
    },
  },
  popper: {
    width: 162,
    background: '#191A19',
    border: '0.5px solid #050505',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.35)',
    borderRadius: 4,
    zIndex: 9999,
    padding: 16,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: '100%',
    color: 'white',
    borderBottom: '1px dashed gray',
    paddingBottom: 16,
    marginBottom: 6
  }
}));
