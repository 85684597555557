import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(() => ({
  root: {
    background: '#191919',
  },
  container: {
    minHeight: '100vh',
    maxWidth: 1440,
    margin: '0 auto',
    padding: '56px 96px',
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  submitButton: {
    fontSize: "16px",
    fontWeight: 500,
    textTransform: "none",
    color: "#50F36A",
    backgroundColor: "#232423",
    borderRadius: "44px",
    height: "32px",
    padding: "0px 16px 0px 16px",
    "& > .MuiButton-label": {
      fontFamily: "Source Sans Pro",
    },
    "&:hover": {
      backgroundColor: "#323432",
    },
    "&.active": {
      backgroundColor: "#282A28",
    },
    "&:disabled": {
      opacity: "0.3",
      color: "#FFFFFF",
    },
  },
  headingWrapper: {
    marginTop: 96,
  },
  headingFont: {
    width: '78%',
    color: 'white',
    fontSize: '6vw',
    letterSpacing: '-0.02em',
    '@media (min-width: 1800px)': {
      fontSize: 92,
    }
  },
  descriptionWrapper: {
    marginTop: 70
  },
  descriptionFont: {
    color: 'white',
    letterSpacing: '-0.02em',
    fontWeight: 500,
    fontSize: 32,
  },
  logoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
    fontSize: 12,
    letterSpacing: '0.2px',
  }
}));
