/* eslint-disable */
import React from 'react';
import useStyles from "./ReportAppBar.styles";
import {Button} from "@material-ui/core";
import { ReactComponent as Caret } from "../assets/images/caret.svg";
import {useHistory} from "react-router-dom";

export default function ReportAppBar() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <Button onClick={() => history.goBack()} className={classes.btn}>
        <Caret/>
      </Button>
      <span>Map</span>
    </div>
  )
}
