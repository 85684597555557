import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import InputField from './InputField';
import axiosInstance from '../utils/axiosApi';
import useStyles from './BetaTesterForm.style';


const RecoverUsernameSchema = Yup.object().shape({
  email: Yup.string().email().required('Required'),
  password: Yup.string()
    .required('Please Enter your password')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
});


const ResetPasswordForm = (props) => {
  const classes = useStyles();
  const [submitted, setSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const { resetToken } = props

  const formFields = {email: '', password: '', token: resetToken};

  const handleSubmit = async (values) => {
    const data = values
    data.token = formFields.token;
    try {
      const response = await axiosInstance.post('/recover/password/reset/', data);
      if (response.status === 200) {
        setSubmitError(false);
        setSubmitted(true);
      } else {
        setSubmitError(true)
      }
    } catch (error) {
      // console.error(error);
      setSubmitError(true);
    }
  }

  return (
    <>
      {!submitted && (
        <Formik
          initialValues={formFields}
          validationSchema={RecoverUsernameSchema}
          onSubmit={handleSubmit}
        >
          {({errors, touched}) => (
            <Form>
              <section className={classes.form}>
                <InputField
                  name='email'
                  label='Email'
                  className={classes.inputField}
                  error={errors.email && touched.email}
                  helperText={errors.email}
                />
                <InputField
                  name='password'
                  label='New Password'
                  className={classes.inputField}
                  error={errors.password && touched.password}
                  helperText={errors.password}
                  type='password'
                />
              </section>
              <div className={classes.submitWrapper}>
                <Button className={classes.submitBtn} type='submit'>Reset Password</Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
      <div className={classes.descriptionWrapper}>
        {submitted && (
          <p>Your password has been successfully reset.</p>
        )}
      </div>
      <div className={classes.errorWrapper}>
        {submitError && (
          <p>There was an error resetting your password.</p>
        )}
      </div>
    </>
  )
};

export default ResetPasswordForm;
