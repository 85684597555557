import React from 'react';
import { Popup } from "react-map-gl";

function BankPopup({ popupInfo, setPopupInfo }) {

  if (!popupInfo) return null;

  return (
    <Popup
      tipSize={5}
      anchor="bottom"
      longitude={popupInfo.longitude}
      latitude={popupInfo.latitude}
      closeOnClick={false}
      onClose={setPopupInfo}
    >
      <pre style={{fontSize: 14}}>{popupInfo.name}</pre>
    </Popup>
  );
}

export default BankPopup;
