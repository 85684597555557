import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Button from "@material-ui/core/Button";
import {useSetRecoilState} from "recoil";
import { Redirect } from "react-router-dom";
import InputField from "./InputField";
import useStyles from './BetaTesterForm.style';
import {globalMessage, isAuthorized} from "../store/Atoms";
import axiosInstance from "../utils/axiosApi";

const SignupSchema = Yup.object().shape({
  username: Yup.string().required('Required'),
  password: Yup.string().required('Please Enter your password')
});

function SigninForm() {
  const classes = useStyles();
  const [redirect, setRedirect] = useState(false);
  const setMessage = useSetRecoilState(globalMessage);
  const setIsAuth = useSetRecoilState(isAuthorized);

  const initial = {
    username: '',
    password: ''
  }

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const response = await axiosInstance.post('/token/obtain/', values);
      axiosInstance.defaults.headers['x-access-token'] = `JWT ${  response.data.access}`;
      localStorage.setItem('access_token', response.data.access);
      localStorage.setItem('refresh_token', response.data.refresh);
      setMessage({
        severity: 'success',
        text: 'You have successfully signed in!'
      })
      setIsAuth(true);
      setRedirect(true);
      resetForm();
    } catch (error) {
      console.log(error.stack);
      setMessage({
        severity: 'error',
        text: 'Error: something went wrong while signing in.'
      });
    }
    return undefined;
  };

  if (redirect) {
    return <Redirect to='/' />;
  }

  return (
    <Formik
       initialValues={initial}
       validationSchema={SignupSchema}
       onSubmit={handleSubmit}
     >
      {/* eslint-disable-next-line no-unused-vars */}
       {({errors, touched}) => (
         <Form>
           <section className={classes.form}>
             <InputField
               name='username'
               label='Username'
               className={classes.inputField}
               error={errors.first_name && touched.first_name}
               helperText={errors.first_name}
             />
             <InputField
               name='password'
               label='Password'
               className={classes.inputField}
               error={errors.password && touched.password}
               helperText={errors.password}
               type='password'
             />
           </section>
           <div className={classes.submitWrapper}>
             <Button
               className={classes.submitBtn}
               type='submit'
             >
               Sign In
             </Button>
           </div>
         </Form>
       )}
     </Formik>
  )
}

export default SigninForm;
