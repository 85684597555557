import React from "react";
import {useRecoilValue} from "recoil";
import {fetchedReportTransactions} from "../store/Selectors";
import UnlinedTable from "./UnlinedTable";

export default function ReportTransactions() {
  const transactions = useRecoilValue(fetchedReportTransactions);

  return (
    <UnlinedTable data={transactions} />
  )
}
