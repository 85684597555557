/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {useRecoilValueLoadable} from 'recoil';
import { Source, Layer } from "react-map-gl";
import {fetchedPermits} from "../store/Selectors";
import { clusterLayer, clusterCountLayer, unclusteredPointLayer } from "../map-style";

function PermitMarkers({ mapRef }) {
  const map = mapRef.current.getMap();
  const permits = useRecoilValueLoadable(fetchedPermits);
  const [cachedPermits, setCachedPermits] = useState(null);

  useEffect(() => {
    switch (permits.state) {
      case 'hasValue':
        setCachedPermits(permits.contents);
        if (cachedPermits) {
          map.moveLayer('clusters');
          map.moveLayer('cluster-count');
        }
        break;
      case 'loading':
        break;
      case 'hasError':
        throw permits.contents;
    }
  }, [permits]);

  if (cachedPermits && cachedPermits.type) {
    return (
      <Source
        id="permits"
        type="geojson"
        data={cachedPermits}
        cluster={true}
        clusterMaxZoom={9}
        clusterRadius={50}
      >
        <Layer {...clusterLayer} />
        <Layer {...clusterCountLayer} />
        <Layer {...unclusteredPointLayer} />
      </Source>
    )
  }

  return null;
}

export default PermitMarkers;
