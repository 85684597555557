/* eslint-disable */
import React, { Suspense, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {
  bankDialogActive,
  showContacts,
  transactionPageIndex,
  transCreditTypeFilters,
  transTypeFilters
} from "../store/Atoms";
import { fetchedBankCredits, fetchedBankTransactions } from "../store/Selectors";
import "react-json-pretty/themes/monikai.css";
import Banksvg from "../assets/images/banksvg.svg";
import useStyles from "./BankDialog.styles";
import UnlinedTable from "./UnlinedTable";
import LinedTable from "./LinedTable";
import { ReactComponent as Close } from "../assets/images/btn-close.svg";
import TransPagination from "./TransPagination";
import TransactionFilter from "./TransactionFilter";
import BankContacts from "./BankContacts";

function BankCredits() {
  const credits = useRecoilValue(fetchedBankCredits);

  return <LinedTable data={credits} />;
}

function BankTransactions() {
  const transactions = useRecoilValue(fetchedBankTransactions);

  return <UnlinedTable data={transactions} />;
}

function BankDialog() {
  const [bankDialog, setBankDialog] = useRecoilState(bankDialogActive);
  const setPage = useSetRecoilState(transactionPageIndex);
  const setTransCredits = useSetRecoilState(transCreditTypeFilters);
  const setTransTypes = useSetRecoilState(transTypeFilters);
  const [checked, setChecked] = useRecoilState(showContacts);
  const classes = useStyles();

  if (!bankDialog) {
    return null;
  }

  const resetStates = () => {
    setBankDialog(null);
    setPage(1);
    setTransCredits({
      Stream: true,
      Wetland: true,
      Species: true,
      Nutrient: true,
    });
    setTransTypes({
      Rel: true,
      Wdr: true,
    });
  }

  return (
    <Dialog
      open={!!bankDialog}
      onClose={() => resetStates()}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <IconButton onClick={() => resetStates()} className={classes.close}>
        <Close />
      </IconButton>

      <p className="flex flex--align-center">
        <img className={classes.img} src={Banksvg}  alt="bank" />
        <p className={classes.smTitle}>Bank</p>
      </p>
      <h1 className={classes.h1}>{bankDialog.name}</h1>
      <Suspense fallback={null}>
        <BankContacts />
      </Suspense>
      <Button
        className={classes.greenBtn}
        onClick={() => setChecked(!checked)}
        style={{ marginTop: 8, maxWidth: 150 }}
      >
        {!checked ? 'Show' : 'Hide'}
        {' '}
        Contacts
      </Button>
      <div className={classes.divider}/>
      <h3 className={classes.h3} style={{ color: "white" }}>
        Credits
      </h3>
      <Suspense fallback={<div>Loading...</div>}>
        <div>
          <BankCredits />
        </div>
      </Suspense>
      <div className="flex flex--align-center flex--justify-sb" style={{ marginTop: 40, paddingBottom: 16 }}>
        <h3 className={classes.h3} style={{ color: "white", padding: 0 }}>
          Transactions
        </h3>
        <Suspense fallback={null}>
          <div className="flex flex--align-center">
            <TransPagination variation="bank" />
            <TransactionFilter filterType="bank-popper" />
          </div>
        </Suspense>
      </div>
      <div className={classes.divider}/>
      <Suspense fallback={<div>Loading...</div>}>
        <div style={{minHeight: 676}}>
          <BankTransactions />
        </div>
      </Suspense>
    </Dialog>
  );
}

export default BankDialog;
