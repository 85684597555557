import React from 'react';
import {numberWithCommas} from "../utils";

export default function SupplyTickers({ tickers }) {
  return (
    <div className="flex" style={{color: 'white', marginTop: 16, height: 12}}>
      <div style={{fontSize: 12}} className="flex flex--column">
        <div className="flex flex--align-end">
          <div style={{height: 1, width: 8, background: 'white'}} />
          <span style={{marginLeft: 4, lineHeight: '7px'}}>Net Inventory</span>
        </div>
        { tickers && (
          <span style={{marginLeft: 11, marginTop: 1}}>{numberWithCommas(parseFloat(tickers.net).toFixed(2))}</span>
        )}
      </div>
      <div style={{fontSize: 12, marginLeft: 24}} className="flex flex--column">
        <div className="flex flex--align-end">
          <div style={{height: 4, width: 8, background: '#50F36A', borderRadius: '1px 1px 0px 0px'}} />
          <span style={{marginLeft: 4, lineHeight: '7px'}}>Releases</span>
        </div>
        { tickers && (
          <span style={{marginLeft: 11, marginTop: 1}}>{numberWithCommas(parseFloat(tickers.rel).toFixed(2))}</span>
        )}
      </div>
      <div style={{fontSize: 12, marginLeft: 18}} className="flex flex--column">
        <div className="flex flex--align-end">
          <div style={{height: 4, width: 8, background: 'white', borderRadius: '1px 1px 0px 0px'}} />
          <span style={{marginLeft: 4, lineHeight: '7px'}}>Withdrawals</span>
        </div>
        { tickers && (
          <span style={{marginLeft: 11, marginTop: 1}}>{numberWithCommas(parseFloat(tickers.wdr).toFixed(2))}</span>
        )}
      </div>
    </div>
  )
}
