import React from 'react';
import {Button} from "@material-ui/core";
import cx from "classnames";
import {useRecoilState} from "recoil";
import Tooltip from '@material-ui/core/Tooltip';
import {supplyPanelActionFilter} from "../store/Atoms";
import useStyles from "./SupplyPanel.styles";

function SupplyCreditFilter() {
  const classes = useStyles();
  const [actionFilter, setActionFilter] = useRecoilState(supplyPanelActionFilter);

  return (
    <div style={{ padding: "14px 0px 14px 0px", borderBottom: "1px dashed grey" }}>
      <Button
        onClick={() => setActionFilter(["Wetland"])}
        className={cx(classes.tiny, { active: actionFilter.includes("Wetland") })}
      >
        Wetland
      </Button>
      <Button
        onClick={() => setActionFilter(["Stream"])}
        className={cx(classes.tiny, { active: actionFilter.includes("Stream") })}
      >
        Stream
      </Button>
      <Tooltip title="Coming Soon!" placement="top">
        <span style={{cursor: "pointer"}}>
          <Button
            onClick={() => setActionFilter(["Nutrient"])}
            className={cx(classes.tiny, { active: actionFilter.includes("Nutrient") })}
            disabled
          >
            Nutrient
          </Button>
        </span>
      </Tooltip>
      <Tooltip title="Coming Soon!" placement="top">
        <span style={{cursor: "pointer"}}>
          <Button
            onClick={() => setActionFilter(["Species"])}
            className={cx(classes.tiny, { active: actionFilter.includes("Species") })}
            disabled
          >
            Species
          </Button>
        </span>
      </Tooltip>
    </div>
  )
}

export default SupplyCreditFilter;
