import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.black4,
  },
  box: {
    padding: "56px",
  },
  close: {
    position: "absolute",
    right: 56,
    top: 56,
  },
  smTitle: {
    fontWeight: 700,
    paddingLeft: "8px",
    opacity: "0.8",
  },
  h1: {
    fontFamily: "Space Grotesk",
    fontWeight: 700,
    fontSize: "32px",
    lineHeight: "40px",
    padding: "16px 0px 16px 0px",
    maxWidth: "501px",
  },
  h3: {
    fontFamily: "Space Grotesk",
    fontSize: "24px",
    fontWeight: "700",
    padding: "40px 0px 16px 0px",
  },
  h4: {
    fontFamily: "Space Grotesk",
    fontSize: "21px",
    fontWeight: "600",
    padding: "0px 0px 16px 0px",
    color: 'white'
  },
  greenBtn: {
    backgroundColor: "#50F36A",
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontWeight: 600,
    textTransform: "none",
    color: "#191919",
    borderRadius: "44px",
    height: "32px",
    padding: "8px 16px 8px 16px",
    maxWidth: "86px",
    margin: "32px 0px 40px 0px",
    "&:hover": {
      backgroundColor: "#AEF9BA",
      color: "#191919",
    },
    "&:active": {
      backgroundColor: "#8DF79E",
      color: "#191919",
    },
    "&:focus": {
      backgroundColor: "#50F36A",
      color: "#191919",
    },
    "&:disabled": {
      opacity: "0.2",
      color: "#191919",
    },
  },
  divider: {
    borderBottom: "1px dashed gray",
    width: "100%",
  },
}));
