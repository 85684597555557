import makeStyles from "@material-ui/core/styles/makeStyles";
// import merge from "lodash.merge";

// eslint-disable-next-line no-unused-vars
export default makeStyles((theme) => ({
  h3: {
    fontFamily: "Space Grotesk",
    fontSize: "24px",
    fontWeight: "700",
    color: "white",
  },

  tiny: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16px",
    color: "#FFFFFF",
    opacity: "0.6",
    minWidth: "unset",
    backgroundColor: "#191919",
    "&.active": {
      color: "#50F36A",
      opacity: "1",
    },
    "&:hover": {
      color: "#50F36A",
      backgroundColor: "#191919",
      opacity: "1",
    },
  },

  xsBtn: {
    fontFamily: "Source Code Pro",
    fontSize: "12px",
    fontWeight: 700,
    textTransform: "uppercase",
    color: "#FFFFFF",
    backgroundColor: "#191919",
    borderRadius: "22px",
    height: "20px",
    marginRight: "16px",
    padding: "4px 8px 4px 8px",
    minWidth: "unset",
    "&.active": {
      backgroundColor: "#50F36A",
      color: "#191919",
    },
    "&:hover": {
      backgroundColor: "#50F36A",
      color: "#191919",
    },
    "&:active": {
      backgroundColor: "#50F36A",
      color: "#191919",
    },
    "&:focus": {
      backgroundColor: "#50F36A",
      color: "#191919",
    },
    "&:disabled": {
      opacity: "0.8",
      color: "#191919",
    },
  },
}));
